/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
/* import { deleteService } from 'src/redux/servicesSlice'; */
import { useDispatch } from 'react-redux';
import Label from 'src/components/Label';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
/* import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'; */
import { useSelector } from 'react-redux';
/* import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'; */
import {
  InputAdornment,
  TextField,
  useTheme,
  Box,
  FormControl,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import { fetchAbonnements } from 'src/redux/subscriptionSlice';
import {
  CardMembership,
  EditTwoTone,
} from '@mui/icons-material';
import AbonnementUpdateModal from './AbonnementUpdateModal';

const SubscriptionTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const recordsTotal = useSelector((x) => x.abonnement.recordsTotal);
  const data = useSelector((x) => x.abonnement.abonnement || []); // Utilisez 'abonnement' au lieu de 'plans'
  const loading = useSelector((x) => x.abonnement.loading); // Utilisez 'abonnement' au lieu de 'plans'

  const [draw, setDraw] = useState(1);
  const [searchValue, setSearchValue] = useState(''); // State for search value
  const [sortColumn, setSortColumn] = useState(0); // State for sort column
  const [sortDirection, setSortDirection] = useState('asc'); // State for sort direction
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const [updateModal, setUpdateModal] = useState(false);
  const [stateModal, setStateModal] = useState(false);

  const [abonnementIdToSelect, setAbonnementIdToSelect] = useState(null);



  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchValue, sortColumn, sortDirection, page, limit]);

  const fetchData = async () => {
    const queryParams = {
      draw,
      columns: [
        {
          data: 'title',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: searchValue, regex: true }
        },
        {
          data: 'little_description',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: '', regex: true }
        },
        {
          data: 'price',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: '', regex: true }
        },
        {
          data: 'type',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: '', regex: true }
        },
        {
          data: 'createdAt',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: '', regex: true }
        },
        {
          data: 'updatedAt',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: '', regex: true }
        },
        {
          data: 'price',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: '', regex: true }
        },
        {
          data: 'active',
          name: '',
          searchable: true,
          orderable: true,
          search: { value: '', regex: true }
        }
      ],
      order: [{ column: sortColumn, dir: sortDirection }],
      start: page * limit,
      length: limit,
      search: { value: searchValue, regex: false },
      _: Date.now()
    };

    dispatch(fetchAbonnements(queryParams));

    setDraw((prevDraw) => prevDraw + 1);
  };

  const getStatusLabelByValue = (cryptoOrderStatus) => {
    const map = {
      verified: {
        text: 'Verified',
        color: '#39b36d'
      },
      declined: {
        text: 'Declined',
        color: 'error'
      }
    };

    const { text, color } = map[cryptoOrderStatus];

    return (
      <Label style={{ width: '100px' }} color={color}>
        {text}
      </Label>
    );
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // sendPaginationToParent();
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    // sendPaginationToParent();
  };

  const getStatusLabel = (cryptoOrderStatus) => {
    const map = {
        true: {
            text: 'Active',
            color: 'primary',
        },
        false: {
            text: 'Inactive',
            color: 'error'
        }
    };

    const { text, color } = map[cryptoOrderStatus];

    return <Label color={color}>{text}</Label>;
};
  return (
    <Card>
      <FormControl sx={{ ml:1, mt:1, mb:1 }}>
        <TextField
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSort(0)}>
              Title{' '}
              {sortColumn === 0 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(1)}>
              Price {'($ / month)'}
              {sortColumn === 4 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(1)}>
              type
              {sortColumn === 4 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>

            <TableCell onClick={() => handleSort(3)}>
              Created At{' '}
              {sortColumn === 2 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(4)}>
              Updated At{' '}
              {sortColumn === 3 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(5)}>
              Status{' '}
              {sortColumn === 7 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? null : data.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={6}
                rowSpan={2}
                style={{ textAlign: 'center' }}
              >
                <Typography variant="body1" color="text.primary">
                  No Data Available
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            data.map((item) => (
              <TableRow hover key={item?.DT_RowId}>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    {/*  <Link
                        to={`details/${item?._id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      > */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CardMembership />
                      {/* <Avatar variant="rounded" alt="f" src="/static/images/avatars/1.jpg" /> */}
                      <span style={{ marginLeft: '10px', color: '#18e68e' }}>
                        {' '}
                        {item?.title?.toUpperCase()}
                      </span>
                    </div>
                    {/* </Link> */}
                  </Typography>
                  {/* <Typography variant="body2" color="text.secondary" noWrap>


                      {new Date(item.createdAt).toLocaleString()}
                    </Typography> */}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <Avatar variant="rounded" alt="f" src="/static/images/avatars/1.jpg" /> */}
                      <span style={{ marginLeft: '10px' }}> {item?.price}</span>
                    </div>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    {item.type}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    {new Date(item?.createdAt).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    {new Date(item?.updatedAt).toLocaleString()}
                  </Typography>
                </TableCell>

                <TableCell>

                  
                {getStatusLabel(item?.active)}

                </TableCell>
                <TableCell>
                  <Tooltip title="Edit Plan" arrow>
                    <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter
                        },
                        color: theme.palette.primary.main
                      }}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAbonnementIdToSelect(item?.DT_RowId);
                        setUpdateModal(true);
                      }}
                    >
                      <EditTwoTone fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  
                 
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box p={2}>
        <TablePagination
          component="div"
          count={recordsTotal || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
      {updateModal ? (
        <AbonnementUpdateModal
          open={updateModal}
          selectedAbonnement={abonnementIdToSelect}
          onClose={() => setUpdateModal(false)}
        />
      ) : null}
    
     
    </Card>
  );
};

export default SubscriptionTable;
