import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './config';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';



const initialState = {
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  profile: {},
  loading: false,
  isSuccess: false,
  errors: [],
  role:''
};


export const signin = createAsyncThunk('auth/signin', async (body, { rejectWithValue }) => {
  try {
    const params = { email: body.email, password: body.password };
    const response = await api.post('/auth/signin', params);

    // Store the data in localStorage
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getProfile = createAsyncThunk('user/profile', async (__, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/user/profile`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});


export const updateProfile = createAsyncThunk('user/updateprofile', async (body, { rejectWithValue, dispatch }) => {
  try {
    const params = { email: body.email, phone: body.phone, firstname:body.firstname , lastname:body.lastname };
    const response = await api.put(`/user/profile`,params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updatePassword = createAsyncThunk('user/updatePassword', async (body, { rejectWithValue, dispatch }) => {
  try {
    const params = { old_password: body.old_password, password: body.password, passwordConfirm:body.passwordConfirm };
    const response = await api.put(`/user/change-password`,params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

// user 
const authSlice = createSlice({
  name: 'superadmin',
  initialState,
  reducers: {

    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.errors=[];
      state.profile={};
      localStorage.clear();
    }
  },
  extraReducers: {
    [signin.pending]: (state) => {
      state.loading = true;
    },
    [signin.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      if (!payload?.accessToken || !payload?.refreshToken) {
        state.isSuccess = false;
        state.errors = ['AccessToken and/or refreshToken not found'];
      }
      else {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        const decodedToken = jwt_decode(payload.accessToken);
        state.email = decodedToken.email;
        state.role = decodedToken.role;
      }
      if(state.role!=="SUPER_ADMIN"){
        state.errors = ['Without authorization, you do not have super admin privileges.'];
      }


      state.loading = false;
    },
    [signin.rejected]: (state, { payload }) => {
      // if (payload.statusCode === 400) {
      state.errors = payload?.message;
      // }
      state.isSuccess = false;
      state.loading = true;
    },
    [getProfile.pending]: (state) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile = payload;
      state.loading = false;
    },
    [getProfile.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile = payload;
      state.loading = false;
    },
    [updateProfile.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updatePassword.pending]: (state) => {
      state.loading = true;
    },
    // eslint-disable-next-line no-unused-vars
    [updatePassword.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [updatePassword.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
