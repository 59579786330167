import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance

const initialState = {
  services: [],
  selected_service: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: [],
};


export const fetchServices = createAsyncThunk('services/fetchServices', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/services', { params });

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchServicesActive = createAsyncThunk('services/fetchServicesActive', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/services/all', { params });

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getServiceById = createAsyncThunk('services/getServiceById', async (serviceId, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/services/${serviceId}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const addService = createAsyncThunk('services/addService', async (serviceData, { rejectWithValue, dispatch }) => {
  try {

    const params = { name: serviceData.serviceName, business_area : serviceData.business_area, active: serviceData.active };
    const response = await api.post('/services', params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteService = createAsyncThunk('services/deleteService', async (serviceId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.delete(`/services/${serviceId}`);

    dispatch(fetchServices());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updateService = createAsyncThunk('services/updateService', async (updatedService, { rejectWithValue, dispatch }) => {
  try {

    // eslint-disable-next-line camelcase
    const { id, name, business_area, active } = updatedService;

    // eslint-disable-next-line camelcase
    const params = { name, business_area, active };
    const response = await api.put(`/services/${id}`, params);

    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchServices.pending]: (state) => {
      state.loading = true;
    },
    [fetchServices.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.services = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;
      state.loading = false;
    },
    [fetchServices.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [deleteService.pending]: (state) => {
      state.loading = true;
    },
    [deleteService.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteService.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addService.pending]: (state) => {
      state.loading = true;
    },
    [addService.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newService = { ...payload, _DT_RowId: payload._id }; // Create a new service object with _DT_RowId attribute
      state.services.push(newService); // Add the new service to the services array
      state.loading = false;
    },
    [addService.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateService.pending]: (state) => {
      state.loading = true;
    },
    [updateService.fulfilled]: (state, { payload }) => {
      // Update the selected service with the updated data
      state.services = state.services.map((service) =>
        service.DT_RowId === payload._id ? { ...payload, DT_RowId: payload._id } : service
        
      );

      state.loading = false;
    },
    [updateService.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [getServiceById.pending]: (state) => {
      state.loading = true;
    },
    [getServiceById.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selected_service = payload;
      state.loading = false;
    },
    [getServiceById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
  }
});

export default servicesSlice.reducer;
