import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {logout} from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  contacts: [],
  recordsTotal: 0,

  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchContacttUs = createAsyncThunk(
  'contactus/fetchContacttUs',
  async (params, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/crm-module/contactus', {params});
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendEmail = createAsyncThunk(
  'contactus/sendEmail',
  async (data, {rejectWithValue, dispatch}) => {
    const {id, message} = data;
    const params = {id, message};
    try {
      const response = await api.post(
        `/crm-module/contactus/sendMail/${id}`,
        params
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const contactUsSlice = createSlice({
  name: 'contactus',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchContacttUs.pending]: (state) => {
      state.loading = true;
    },
    [fetchContacttUs.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;

      state.contacts = payload || {};
      state.recordsTotal = payload?.recordsTotal;
      state.loading = false;
    },
    [fetchContacttUs.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [sendEmail.pending]: (state) => {
      state.loading = true;
    },
    [sendEmail.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.contacts = state.contacts;
      state.loading = false;
    },
    [sendEmail.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default contactUsSlice.reducer;
