/* eslint-disable camelcase */
import { Card } from '@mui/material';
/* import CompaniesTable from './CompaniesTable'; */
import { Outlet } from 'react-router';

function Subscriptions() {
 


  return (
    <Card>
      {/* <CompaniesTable /> */}
      <Outlet />
    </Card>
  );
}

export default Subscriptions;
