import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance

const initialState = {
  businessAreas: [],
  selected_businessArea: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: [],
};


export const fetchbusinessAreas = createAsyncThunk('businessArea/fetchbusinessArea', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/businessarea', { params });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchbusinessAreasActive = createAsyncThunk('businessArea/fetchbusinessAreasActive', async ( { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/businessarea/all');
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getbusinessAreaById = createAsyncThunk('businessArea/getbusinessAreaById', async (businessAreaId, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/businessArea/${businessAreaId}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const addbusinessArea = createAsyncThunk('businessArea/addbusinessArea', async (businessAreaData, { rejectWithValue, dispatch }) => {
  try {

    const params = { business_name: businessAreaData.business_name, active: businessAreaData.active };
    const response = await api.post('/businessarea', params);
    dispatch(fetchbusinessAreas());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deletebusinessArea = createAsyncThunk('businessarea/deleteBusinessarea', async (businessareaId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.delete(`/businessarea/${businessareaId}`);

    dispatch(fetchbusinessAreas());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updatebusinessArea = createAsyncThunk('businessarea/updatebusinessarea', async (updatedbusinessarea, { rejectWithValue, dispatch }) => {
  try {

    // eslint-disable-next-line camelcase
    const { id, business_name, active } = updatedbusinessarea;

    // eslint-disable-next-line camelcase
    const params = { business_name, active };
    const response = await api.put(`/businessarea/${id}`, params);

    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

const businessAreaSlice = createSlice({
  name: 'businessarea',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchbusinessAreas.pending]: (state) => {
      state.loading = true;
    },
    [fetchbusinessAreas.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.businessAreas = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;
      state.loading = false;
    },
    [fetchbusinessAreas.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [deletebusinessArea.pending]: (state) => {
      state.loading = true;
    },
    [deletebusinessArea.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [deletebusinessArea.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addbusinessArea.pending]: (state) => {
      state.loading = true;
    },
    [addbusinessArea.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newBusinessArea = { ...payload, _DT_RowId: payload._id }; // Create a new service object with _DT_RowId attribute
      state.businessAreas.push(newBusinessArea); // Add the new service to the services array
      state.loading = false;
    },
    [addbusinessArea.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updatebusinessArea.pending]: (state) => {
      state.loading = true;
    },
    [updatebusinessArea.fulfilled]: (state, { payload }) => {
      // Update the selected service with the updated data
      state.businessAreas = state.businessAreas.map((businessArea) =>
      businessArea.DT_RowId === payload._id ? { ...payload, DT_RowId: payload._id } : businessArea
        
      );

      state.loading = false;
    },
    [updatebusinessArea.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [getbusinessAreaById.pending]: (state) => {
      state.loading = true;
    },
    [getbusinessAreaById.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selected_businessArea = payload;
      state.loading = false;
    },
    [getbusinessAreaById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },


  }

});

export default businessAreaSlice.reducer;
