import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  AppServices: [],
  recordsTotal: 0,
  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchAppService = createAsyncThunk(
  'appservice/fetchAppService',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/crm-module/appService', { params });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateAppService = createAsyncThunk(
  'appservice/updateAppService',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { id, title, description } = data;

      const params = { title, description };

      const response = await api.put(`/crm-module/appService/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const addAppService = createAsyncThunk(
  'appservice/addAppService',
  async (appService, { rejectWithValue, dispatch }) => {
    try {
      const bodyData = {
        title: appService.title,
        description: appService.description
      };
      const response = await api.post('/crm-module/appservice/add', bodyData);

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const archiveAppService = createAsyncThunk(
  'appservice/archiveAppService',
  async (appService, { rejectWithValue, dispatch }) => {
    try {
      const id = appService.DT_RowId;
      const data = { active: !appService.active };

      const response = await api.put(
        `/crm-module/appservice/archive/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const AppsServiceSlice = createSlice({
  name: 'appservice',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchAppService.pending]: (state) => {
      state.loading = true;
    },
    [fetchAppService.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.AppServices = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;

      state.loading = false;
    },
    [fetchAppService.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateAppService.pending]: (state) => {
      state.loading = true;
    },
    [updateAppService.fulfilled]: (state, { payload }) => {
      state.AppServices = state.AppServices.map((AppService) =>
        AppService.DT_RowId === payload._id
          ? { ...payload, DT_RowId: payload._id }
          : AppService
      );
      state.loading = false;
    },
    [updateAppService.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addAppService.pending]: (state) => {
      state.loading = true;
    },
    [addAppService.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const appService = { ...payload };
      state.AppServices.push(appService);
      state.loading = false;
    },
    [addAppService.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [archiveAppService.pending]: (state) => {
      state.loading = true;
    },
    [archiveAppService.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.AppServices = state.AppServices.map((AppService) =>
        AppService.DT_RowId === payload._id
          ? { ...payload, DT_RowId: payload._id }
          : AppService
      );
      state.loading = false;
    },
    [archiveAppService.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default AppsServiceSlice.reducer;
