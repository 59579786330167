/* eslint-disable no-unused-vars */
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {LoadingButton} from '@mui/lab';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import {
  fetchAbonnements,
  getAbonnementById,
  updateAbonnementById
} from 'src/redux/subscriptionSlice';
import {CloseTwoTone, EditTwoTone, Remove, RemoveTwoTone} from '@mui/icons-material';

const AbonnementUpdateModal = ({open, onClose, selectedAbonnement}) => {
  const dispatch = useDispatch();

  const abonnement = useSelector((x) => x.abonnement.selected_abonnement);
  const loading = useSelector((x) => x.abonnement.loading);
  const [title, setTitle] = useState(abonnement?.title || '');
  const [little_description, setLittle_description] = useState(
    abonnement?.little_description || ''
  );
  const [price, setPrice] = useState(abonnement?.price || '');
  const [details, setDetails] = useState(abonnement?.details || []);
  const [active, setActive] = useState(abonnement?.active || true);

  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [quarterlyDiscount, setQuarterlyDiscount] = useState(0);
  const [semiAnnualDiscount, setSemiAnnualDiscount] = useState(0);
  const [annualDiscount, setAnnualDiscount] = useState(0);
  const [monthly, setMonthly] = useState('Monthly');
  const [quarterly, setQuarterly] = useState('Quarterly');
  const [semiAnnual, setSemiAnnual] = useState('Semi-Annual');
  const [annual, setAnnual] = useState('Annual');

  const [type, setType] = useState(abonnement?.type || '');

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dispatch(getAbonnementById(selectedAbonnement));
  }, [selectedAbonnement]);
  useEffect(() => {
    if (abonnement) {
      setTitle(abonnement.title);
      setLittle_description(abonnement.little_description);
      setPrice(abonnement.price);
      setDetails(abonnement.details);
      setActive(abonnement?.active);
      setType(abonnement.type);
      setMonthly(abonnement.monthly);
      setQuarterly(abonnement?.quarterly);
      setSemiAnnual(abonnement?.semiAnnual);
      setAnnual(abonnement?.annual);

      setMonthlyDiscount(abonnement?.monthlyDiscount);
      setQuarterlyDiscount(abonnement?.quarterlyDiscount);
      setSemiAnnualDiscount(abonnement?.semiAnnualDiscount);
      setAnnualDiscount(abonnement?.annualDiscount);
    }
  }, [abonnement]);

  const handleSubmit = () => {
    if (
      !title ||
      !little_description ||
      price < 0 ||
      details.length === 0 ||
      type === ''
    ) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    }
    const subscriptionData = {
      id: selectedAbonnement,
      title,
      little_description,
      price: parseFloat(price),
      details,
      active,
      type,
      monthlyDiscount: parseInt(monthlyDiscount),
      quarterlyDiscount: parseInt(quarterlyDiscount),
      semiAnnualDiscount: parseInt(semiAnnualDiscount),
      annualDiscount: parseInt(annualDiscount),
      monthly: type === 'Pay' ? monthly : '',
      quarterly: type === 'Pay' ? quarterly : '',
      semiAnnual: type === 'Pay' ? semiAnnual : '',
      annual: type === 'Pay' ? annual : ''
    };

    dispatch(updateAbonnementById(subscriptionData))
      .unwrap()
      .then(() => {
        setTitle('');
        setLittle_description('');
        setPrice(0);
        setDetails([]);
        setMonthlyDiscount(0);
        setQuarterlyDiscount(0);
        setSemiAnnualDiscount(0);
        setAnnualDiscount(0);
        setMonthly('');
        setQuarterly('');
        setSemiAnnual('');
        setAnnual('');
        setErrorMessage('');
        // setActive(true);
        setType('');
        onClose();
        console.log(subscriptionData);
        dispatch(fetchAbonnements());
      })
      .catch((error) => {
        // Handle error if necessary
        setErrorMessage(error?.message);
      });
  };

  const handleAddField = () => {
    setDetails([...details, '']);
  };

  const handleFieldChange = (index, value) => {
    const newFields = [...details];
    newFields[index] = value;
    setDetails(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = [...details];
    newFields.splice(index, 1);
    setDetails(newFields);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Subscription</DialogTitle>
      {loading ? (
        <LoadingButton
          // eslint-disable-next-line react/jsx-no-bind

          loading={loading}
          variant="outlined"
          color="primary"
          startIcon={<RefreshTwoToneIcon />}
        />
      ) : (
        <DialogContent>
          {errorMessage && (
            <Typography variant="body2" color="error" marginTop={1}>
              {errorMessage}
            </Typography>
          )}
          <TextField
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Description"
            value={little_description}
            onChange={(e) => {
              setLittle_description(e.target.value);
            }}
            fullWidth
            margin="normal"
            multiline={true}
          />

            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value="Free">Free</MenuItem>
                <MenuItem value="Pay">Pay</MenuItem>
              </Select>
            </FormControl>
   

          {type === 'Free' ? (
            <></>
          ) : (
            <TextField
              label="Price"
              type="number"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              fullWidth
              margin="normal"
            />
          )}

          <Grid
            container
            spacing={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Monthly</InputLabel>
                  <Select
                    label="Monthly"
                    value={monthly}
                    onChange={(e) => setMonthly(e.target.value)}
                  >
                    <MenuItem value="Monthly">Monthly</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Quarterly</InputLabel>
                  <Select
                    label="Quarterly"
                    value={quarterly}
                    onChange={(e) => setQuarterly(e.target.value)}
                  >
                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Semi-Annual</InputLabel>
                  <Select
                    label="Semi-Annual"
                    value={semiAnnual}
                    onChange={(e) => setSemiAnnual(e.target.value)}
                  >
                    <MenuItem value="Semi-Annual">Semi-Annual</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Annual</InputLabel>
                  <Select
                    label="Annual"
                    value={annual}
                    onChange={(e) => setAnnual(e.target.value)}
                  >
                    <MenuItem value="Annual">Annual</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Monthly Discount</InputLabel>
                  <Select
                    label="Monthly Discount"
                    value={monthlyDiscount}
                    onChange={(e) => setMonthlyDiscount(e.target.value)}
                  >
                    <MenuItem value={0}>0 %</MenuItem>
                    <MenuItem value={5}>5 %</MenuItem>
                    <MenuItem value={10}>10 %</MenuItem>
                    <MenuItem value={15}>15 %</MenuItem>
                    <MenuItem value={20}>20 %</MenuItem>
                    <MenuItem value={25}>25 %</MenuItem>
                    <MenuItem value={30}>30 %</MenuItem>
                    <MenuItem value={35}>35 %</MenuItem>
                    <MenuItem value={40}>40 %</MenuItem>
                    <MenuItem value={45}>45 %</MenuItem>
                    <MenuItem value={50}>50 %</MenuItem>
                    <MenuItem value={55}>55 %</MenuItem>
                    <MenuItem value={60}>60 %</MenuItem>
                    <MenuItem value={65}>65 %</MenuItem>
                    <MenuItem value={70}>70 %</MenuItem>
                    <MenuItem value={75}>75 %</MenuItem>
                    <MenuItem value={80}>80 %</MenuItem>
                    <MenuItem value={85}>85 %</MenuItem>
                    <MenuItem value={90}>90 %</MenuItem>
                    <MenuItem value={95}>95 %</MenuItem>
                    <MenuItem value={100}>100 %</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Quarterly Discount</InputLabel>
                  <Select
                    label="Quarterly Discount"
                    value={quarterlyDiscount}
                    onChange={(e) => setQuarterlyDiscount(e.target.value)}
                  >
                    <MenuItem value={0}>0 %</MenuItem>
                    <MenuItem value={5}>5 %</MenuItem>
                    <MenuItem value={10}>10 %</MenuItem>
                    <MenuItem value={15}>15 %</MenuItem>
                    <MenuItem value={20}>20 %</MenuItem>
                    <MenuItem value={25}>25 %</MenuItem>
                    <MenuItem value={30}>30 %</MenuItem>
                    <MenuItem value={35}>35 %</MenuItem>
                    <MenuItem value={40}>40 %</MenuItem>
                    <MenuItem value={45}>45 %</MenuItem>
                    <MenuItem value={50}>50 %</MenuItem>
                    <MenuItem value={55}>55 %</MenuItem>
                    <MenuItem value={60}>60 %</MenuItem>
                    <MenuItem value={65}>65 %</MenuItem>
                    <MenuItem value={70}>70 %</MenuItem>
                    <MenuItem value={75}>75 %</MenuItem>
                    <MenuItem value={80}>80 %</MenuItem>
                    <MenuItem value={85}>85 %</MenuItem>
                    <MenuItem value={90}>90 %</MenuItem>
                    <MenuItem value={95}>95 %</MenuItem>
                    <MenuItem value={100}>100 %</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Semi-Annual Discount</InputLabel>
                  <Select
                    label="Semi-Annual Discount"
                    value={semiAnnualDiscount}
                    onChange={(e) => setSemiAnnualDiscount(e.target.value)}
                  >
                    <MenuItem value={0}>0 %</MenuItem>
                    <MenuItem value={5}>5 %</MenuItem>
                    <MenuItem value={10}>10 %</MenuItem>
                    <MenuItem value={15}>15 %</MenuItem>
                    <MenuItem value={20}>20 %</MenuItem>
                    <MenuItem value={25}>25 %</MenuItem>
                    <MenuItem value={30}>30 %</MenuItem>
                    <MenuItem value={35}>35 %</MenuItem>
                    <MenuItem value={40}>40 %</MenuItem>
                    <MenuItem value={45}>45 %</MenuItem>
                    <MenuItem value={50}>50 %</MenuItem>
                    <MenuItem value={55}>55 %</MenuItem>
                    <MenuItem value={60}>60 %</MenuItem>
                    <MenuItem value={65}>65 %</MenuItem>
                    <MenuItem value={70}>70 %</MenuItem>
                    <MenuItem value={75}>75 %</MenuItem>
                    <MenuItem value={80}>80 %</MenuItem>
                    <MenuItem value={85}>85 %</MenuItem>
                    <MenuItem value={90}>90 %</MenuItem>
                    <MenuItem value={95}>95 %</MenuItem>
                    <MenuItem value={100}>100 %</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              {type === 'Pay' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Annual Discount</InputLabel>
                  <Select
                    label="Annual Discount"
                    value={annualDiscount}
                    onChange={(e) => setAnnualDiscount(e.target.value)}
                  >
                    <MenuItem value={0}>0 %</MenuItem>
                    <MenuItem value={5}>5 %</MenuItem>
                    <MenuItem value={10}>10 %</MenuItem>
                    <MenuItem value={15}>15 %</MenuItem>
                    <MenuItem value={20}>20 %</MenuItem>
                    <MenuItem value={25}>25 %</MenuItem>
                    <MenuItem value={30}>30 %</MenuItem>
                    <MenuItem value={35}>35 %</MenuItem>
                    <MenuItem value={40}>40 %</MenuItem>
                    <MenuItem value={45}>45 %</MenuItem>
                    <MenuItem value={50}>50 %</MenuItem>
                    <MenuItem value={55}>55 %</MenuItem>
                    <MenuItem value={60}>60 %</MenuItem>
                    <MenuItem value={65}>65 %</MenuItem>
                    <MenuItem value={70}>70 %</MenuItem>
                    <MenuItem value={75}>75 %</MenuItem>
                    <MenuItem value={80}>80 %</MenuItem>
                    <MenuItem value={85}>85 %</MenuItem>
                    <MenuItem value={90}>90 %</MenuItem>
                    <MenuItem value={95}>95 %</MenuItem>
                    <MenuItem value={100}>100 %</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>

          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
            }
            label="Visibility"
          />

          <Button
            color="primary"
            style={{borderRadius: 30}}
            onClick={handleAddField}
          >
            <EditTwoTone /> Edit Some Details
          </Button>
          <Grid
            container justifyContent="space-between" alignItems="center"
          >
            {details.map((value, index) => (
              <Grid
                key={index}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid key={index} item lg={9} md={9} sm={9} style={{marginTop:10}}>
                  <TextField
                    label={`${index}. Detail`}
                    type="text"
                    value={value}
                    onChange={(e) => handleFieldChange(index, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="error"
                    onClick={() => handleRemoveField(index)}
                  >
                    <Remove />Remove
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      )}

      <DialogActions
        style={{
          marginBottom: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          sx={{borderRadius: '30px', border: '1px solid white', color: 'white'}}
          onClick={onClose}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          sx={{borderRadius: '30px'}}
          onClick={handleSubmit}
          disabled={loading}
        >
          Yes, Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AbonnementUpdateModal;
