/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Card } from '@mui/material';
import SuggestionsNotTreatedTable from './SuggestionsNotTreatedTable';

function SuggestionsNotTreated() {

  return (
    <Card>
       <SuggestionsNotTreatedTable />
    </Card>
  );
}

export default SuggestionsNotTreated;
