/* eslint-disable no-unused-vars */
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  Grid
} from '@mui/material';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {LoadingButton} from '@mui/lab';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import {fetchById, fetchPayments, updatePayment} from 'src/redux/PaymentSlice';
import Label from 'src/components/Label';

const ViewPayment = ({open, onClose, selectedPayment}) => {
  const dispatch = useDispatch();
  const pay = useSelector((x) => x.payment.selected_payment);
  const loading = useSelector((x) => x.payment.loading);
  const [brand, setBrand] = useState(pay?.brand || '');
  const [last4, setLast4] = useState(pay?.last4 || '');
  const [exp_month, setExp_month] = useState(pay?.exp_month || '');
  const [exp_year, setExp_year] = useState(pay?.exp_year || '');
  const [status, setStatus] = useState(pay?.status || '');
  const [postal_code, setPostal_code] = useState(pay?.postal_code || '');
  const [price_ht, setPrice_ht] = useState(pay?.price_ht || '');
  const [tva, setTva] = useState(pay?.tva || '');
  const [price_net, setPrice_net] = useState(pay?.price_net || '');
  const [customer, setCustomer] = useState(pay?.customer || null);
  const [abonnement, setAbonnement] = useState(pay?.abonnement || null);
  const [createdAt, setCreatedAt] = useState(pay?.createdAt || '');
  const [updatedAt, setUpdatedAt] = useState(pay?.updatedAt || '');

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dispatch(fetchById(selectedPayment));
  }, [selectedPayment]);
  useEffect(() => {
    if (pay) {
      setBrand(pay?.brand);
      setLast4(pay?.last4);
      setExp_month(pay?.exp_month);
      setExp_year(pay?.exp_year);
      setStatus(pay?.status);
      setPostal_code(pay?.postal_code);
      setPrice_ht(pay?.price_ht);
      setTva(pay?.tva);
      setPrice_net(pay?.price_net);
      setCustomer(pay?.customer);
      setAbonnement(pay?.abonnement);
      setCreatedAt(pay?.createdAt);
      setUpdatedAt(pay?.updatedAt);
    }
    console.log('pay view' + JSON.stringify(pay));
  }, [pay]);

  const handleSubmit = () => {
    const paymentData = {
      id: selectedPayment,
      brand,
      last4,
      exp_month,
      exp_year,
      status,
      postal_code,
      price_ht,
      tva,
      price_net,
      customer,
      abonnement,
      createdAt,
      updatedAt
    };

    dispatch(updatePayment(paymentData))
      .unwrap()
      .then(() => {
        setStatus('');
        onClose();
        dispatch(fetchPayments());
      })
      .catch((error) => {
        // Handle error if necessary
        setErrorMessage(error?.message);
      });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedDateTime = `${formattedDate} - ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Payment Details</DialogTitle>
      {loading ? (
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          startIcon={<RefreshTwoToneIcon />}
        />
      ) : (
        <DialogContent style={{width: 400}}>
          {errorMessage && (
            <Typography variant="body2" color="error" marginTop={1}>
              {errorMessage}
            </Typography>
          )}
       
        </DialogContent>
      )}

      <DialogActions
        style={{
          marginBottom: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          sx={{borderRadius: '30px', border: '1px solid white', color: 'white'}}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewPayment;
