import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
  abonnement: [],
  selected_abonnement: null,
  loading: false,
  isSuccess: false,
  errors: [],
  recordsTotal: 0
};

export const fetchAbonnements = createAsyncThunk(
  'abonnement/fetchAbonnements',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/abonnement', { params });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAbonnement = createAsyncThunk(
  'abonnement/addAbonnement',
  async (abonnementData, { rejectWithValue, dispatch }) => {
    try {
      const params = {
        title: abonnementData.title,
        little_description: abonnementData.little_description,
        price: abonnementData.price,
        details: abonnementData.details,
        active: abonnementData.active,
        type: abonnementData.type,
        monthlyDiscount : abonnementData.monthlyDiscount,
        quarterlyDiscount : abonnementData.quarterlyDiscount,
        semiAnnualDiscount : abonnementData.semiAnnualDiscount,
        annualDiscount : abonnementData.annualDiscount,
        monthly : abonnementData.monthly,
        quarterly : abonnementData.quarterly,
        semiAnnual : abonnementData.semiAnnual,
        annual : abonnementData.annual,
      };
      const response = await api.post('/abonnement/add', params);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAbonnementById = createAsyncThunk(
  'abonnement/getAbonnementById',
  async (abonnementId, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/abonnement/${abonnementId}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAbonnementById = createAsyncThunk(
  'abonnement/updateAbonnementById',
  async (updatedAbonnement, { rejectWithValue, dispatch }) => {
    try {
      // eslint-disable-next-line camelcase
      const { id, title, little_description, price, details, active, type, monthly , quarterly, semiAnnual, annual, monthlyDiscount, quarterlyDiscount, semiAnnualDiscount, annualDiscount } =
        updatedAbonnement;

      // eslint-disable-next-line camelcase
      const params = {
        id,
        title,
        little_description,
        price,
        details,
        active: Boolean(active), // Convertissez active en booléen
        type,
        monthlyDiscount,
        quarterlyDiscount,
        semiAnnualDiscount,
        annualDiscount,
        monthly,
        quarterly,
        semiAnnual,
        annual
      };
      const response = await api.put(`/abonnement/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const subscriptionSlice = createSlice({
  name: 'abonnement',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAbonnements.pending]: (state) => {
      state.loading = true;
    },
    [fetchAbonnements.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.abonnement = payload.data || [];
      state.recordsTotal = payload.recordsTotal || 0;
      state.loading = false;
    },
    [fetchAbonnements.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addAbonnement.pending]: (state) => {
      state.loading = true;
    },
    [addAbonnement.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newAbonnement = { ...payload, _DT_RowId: payload._id }; // Create a new service object with _DT_RowId attribute
      state.abonnement.push(newAbonnement); // Add the new service to the services array
      state.loading = false;
    },
    [addAbonnement.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateAbonnementById.pending]: (state) => {
      state.loading = true;
    },
    [updateAbonnementById.fulfilled]: (state, { payload }) => {
      // Mettez à jour l'abonnement correspondant
      state.abonnement = state.abonnement.map((abonnement) =>
        abonnement._id === payload._id
          ? { ...abonnement, ...payload }
          : abonnement
      );
      state.loading = false;
    },
    [updateAbonnementById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [getAbonnementById.pending]: (state) => {
      state.loading = true;
    },
    [getAbonnementById.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selected_abonnement = payload;
      state.loading = false;
    },
    [getAbonnementById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
  }
});

export default subscriptionSlice.reducer;
