import React, {useEffect, useState} from 'react';

import SearchIcon from '@mui/icons-material/Search';
/* import { deleteService } from 'src/redux/servicesSlice'; */
import {useDispatch} from 'react-redux';
import Label from 'src/components/Label';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import ToastSuccess from '../../../components/ToastSuccess/ToastSuccess';
import 'react-toastify/dist/ReactToastify.css';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

/* import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'; */
import {
  Tooltip,
  InputAdornment,
  TextField,
  useTheme,
  Box,
  FormControl,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {fetchCustomers} from 'src/redux/customerSlice';
import {Check, Close, EditTwoTone} from '@mui/icons-material';

const CustomersTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const [totalRecord , setTotalRecord] = useState(0);
  const recordsTotal = useSelector((x) => x.customers.recordsTotal);
  // const [data, setData] = useState([]);
  const data = useSelector((x) => x.customers.customers || []);

  const loading = useSelector((x) => x.customers.loading);

  const [draw, setDraw] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const [sortColumn, setSortColumn] = useState(0); // State for sort column
  const [sortDirection, setSortDirection] = useState('asc'); // State for sort direction
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchValue, sortColumn, sortDirection, page, limit]);

  const fetchData = async () => {
    const queryParams = {
      draw,
      columns: [
        {
          data: 'email',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: searchValue, regex: true}
        },
        {
            data: 'email_confirm',
            name: '',
            searchable: true,
            orderable: true,
            search: {value: '', regex: true}
          },
        {
          data: 'phone',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
            data: 'phone_confirm',
            name: '',
            searchable: true,
            orderable: true,
            search: {value: '', regex: true}
          },
        {
          data: 'createdAt',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'updatedAt',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        }
      ],
      order: [{column: sortColumn, dir: sortDirection}],
      start: page * limit,
      length: limit,
      search: {value: searchValue, regex: true},
      _: Date.now()
    };

    dispatch(fetchCustomers(queryParams));
    setDraw((prevDraw) => prevDraw + 1);
  };

  const getStatusLabel = (cryptoOrderStatus) => {
    const map = {
      true: {
        text: 'Verified',
        color: 'success'
      },
      false: {
        text: 'Not Verified',
        color: 'error'
      }
    };

    const {text, color} = map[cryptoOrderStatus];

    return <Label color={color}>{text}</Label>;
  };
  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };
  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // sendPaginationToParent();
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    // sendPaginationToParent();
  };

  return (
    <Card>
      <ToastSuccess />
      <FormControl sx={{ml: 1, mt:1, mb:1}}>
        <TextField
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSort(0)}>
              Email{' '}
              {sortColumn === 0 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
           

            <TableCell onClick={() => handleSort(1)}>
              Phone{' '}
              {sortColumn === 1 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
         
            <TableCell onClick={() => handleSort(2)}>
              Created At{' '}
              {sortColumn === 2 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(3)}>
              Updated At{' '}
              {sortColumn === 3 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? null : data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} rowSpan={2} style={{textAlign: 'center'}}>
                <Typography variant="body1" color="text.primary">
                  No Data Available
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            data.map((item) => (
              <TableRow hover key={item?.DT_RowId}>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {item?.email_confirm ? (
                        <Tooltip title="Verified email">
                           <Check color='primary' style={{fotnSize:12}}/>
                        </Tooltip>
                       
                      ) : (
                        <Tooltip title="Not verified email">
                        <Close color='error' style={{fotnSize:12}}/>
                     </Tooltip>
                      )}
                      <span style={{marginLeft: '10px'}}> {item?.email} </span>
                    </div>
                  </Typography>
                </TableCell>
               

                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {item?.phone_confirm ? (
                        <Tooltip title="Verified phone">
                           <Check color='primary' style={{fotnSize:12}}/>
                        </Tooltip>
                       
                      ) : (
                        <Tooltip title="Not verified phone">
                        <Close color='error' style={{fotnSize:12}}/>
                     </Tooltip>
                      )}
                      <span style={{marginLeft: '10px'}}> {item?.phone} </span>
                    </div>
                  </Typography>
                </TableCell>

           
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    {new Date(item.createdAt).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    {new Date(item.updatedAt).toLocaleString()}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Tooltip title="More info" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {background: theme.colors.error.lighter},
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        /*    onClick={() => {
                                                    setbusinessAreaIdToSelect(item.DT_RowId);
                                                    setDeleteConfirmation(true);
                                                }} */
                      >
                        <EditTwoTone fontSize="small" />
                      </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box p={2}>
        <TablePagination
          component="div"
          count={recordsTotal || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default CustomersTable;
