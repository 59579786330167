/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';

import SearchIcon from '@mui/icons-material/Search';
/* import { deleteService } from 'src/redux/servicesSlice'; */
import {useDispatch} from 'react-redux';
import Label from 'src/components/Label';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
/* import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'; */
import {useSelector} from 'react-redux';
/* import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'; */
import {
  InputAdornment,
  TextField,
  useTheme,
  Box,
  FormControl,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import {Flag, PercentOutlined} from '@mui/icons-material';
import {fetchTaxAnnuaire} from 'src/redux/taxSlice';

const Annuaire = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const recordsTotal = useSelector((x) => x.tax.recordsTotal);
  const data = useSelector((x) => x.tax.annuaire || []);
  const loading = useSelector((x) => x.tax.loading);

  const [draw, setDraw] = useState(1);
  const [searchValue, setSearchValue] = useState(''); // State for search value
  const [sortColumn, setSortColumn] = useState(0); // State for sort column
  const [sortDirection, setSortDirection] = useState('asc'); // State for sort direction
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchValue, sortColumn, sortDirection, page, limit]);

  const fetchData = async () => {
    const queryParams = {
      draw,
      columns: [
        {
          data: 'name',
          name: 'name',
          searchable: true,
          orderable: true,
          search: {value: searchValue, regex: false} // Changer la configuration de recherche si nécessaire
        },
        {
          data: 'value', // Changer la colonne de recherche si nécessaire
          name: '',
          searchable: true,
          orderable: true,
          search: {value: searchValue, regex: false} // Changer la configuration de recherche si nécessaire
        }
      ],
      order: [{column: sortColumn, dir: sortDirection}],
      start: page * limit,
      length: limit,
      search: {value: searchValue, regex: false}, // Changer la configuration de recherche si nécessaire
      _: Date.now()
    };

    console.log("fetch"+JSON.stringify(data));
  
    dispatch(fetchTaxAnnuaire(queryParams));
  
    setDraw((prevDraw) => prevDraw + 1);
  };

 // console.log("data get it "+JSON.stringify(data))

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    console.log("eevent "+JSON.stringify(e.target.value));
  
    // Vous pouvez ajouter ici un délai pour éviter de déclencher la recherche à chaque frappe de touche
    // Vous pouvez utiliser setTimeout pour cela
    // setTimeout(fetchData, 300); // Recherche après 300 ms
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // sendPaginationToParent();
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    // sendPaginationToParent();
  };

  const getStatusLabel = (cryptoOrderStatus) => {
    const map = {
      true: {
        text: 'Active',
        color: 'success'
      },
      false: {
        text: 'Inactive',
        color: 'error'
      }
    };

    const {text, color} = map[cryptoOrderStatus];

    return <Label color={color}>{text}</Label>;
  };

  return (
    <Card>
      <FormControl sx={{ml: 1, mt:1, mb:1}}>
        <TextField
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSort(0)}>
              Country{' '}
              {sortColumn === 0 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(1)}>
              Value{' '}
              {sortColumn === 0 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? null : data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} rowSpan={2} style={{textAlign: 'center'}}>
                <Typography variant="body1" color="text.primary">
                  No Data Available
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            data.map((item) => (
              <TableRow hover key={item?.id}>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                  >
                    {/*  <Link
                        to={`details/${item?._id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      > */}
                    <Flag />
                    {/* <Avatar variant="rounded" alt="f" src="/static/images/avatars/1.jpg" /> */}
                    <span style={{marginLeft: '10px', color: '#18e68e'}}>
                      {' '}
                      {item?.name}
                    </span>
                    {/* </Link> */}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                  >
                    <span style={{marginLeft: '10px', color: '#fff'}}>
                      {' '}
                      {(Math.random() * 20).toFixed(2)}%{' '}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box p={2}>
        <TablePagination
          component="div"
          count={recordsTotal || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30, 50]}
        />
      </Box>
    </Card>
  );
};

export default Annuaire;
