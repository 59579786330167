import { useEffect, useRef, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { getProfile } from 'src/redux/authSlice';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/redux/authSlice';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((x) => x.user);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
 
  const user = {
    name: (authUser?.profile?.firstname && authUser?.profile?.lastname) ?
      `${authUser.profile.firstname} ${authUser.profile.lastname}` :
      authUser?.profile?.email || '',
    avatar: '/static/images/avatars/4.jpg',
    jobtitle: authUser?.profile?.userKind || '',
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(()=>{
    dispatch(getProfile());
  },[])

  return (
    <>
      <UserBoxButton 
      sx={{
        marginRight: { xs: "-30px",sm:"auto"} 
      }}
      color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src="https://lh3.googleusercontent.com/a-/ALV-UjV-SBfCRigP27XEkIh3GY5Yay73hweRiC7a0UBSmxutBA=s110-p-k-rw-no" />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1" style={{color:"white"}}>{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2" style={{color:"white"}}>{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src="https://lh3.googleusercontent.com/a-/ALV-UjV-SBfCRigP27XEkIh3GY5Yay73hweRiC7a0UBSmxutBA=s110-p-k-rw-no" />
          <UserBoxText>
            <UserBoxLabel variant="body1" style={{color:"white"}}>{user.name}</UserBoxLabel>
            
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/management/profile/details" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" style={{color:"white"}}/>
            <ListItemText primary="My Profile" style={{color:"white"}}/>
          </ListItem>

          <ListItem
            button
            to="/management/profile/settings"
            component={NavLink} style={{color:"white"}}
          >
            <AccountTreeTwoToneIcon fontSize="small" style={{color:"white"}}/>
            <ListItemText primary="Account Settings" style={{color:"white"}}/>
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth
          onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
