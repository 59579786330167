import { Box } from '@mui/material';
import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';
import Website from './Website/Index';

function HeaderButtons() {
  return (
    <Box 
    
    sx={{
      display:'flex',
      ml:{xs: "-150px", sm: "-150px"}
    }}
    >
      <HeaderSearch />
      <Website />
      <HeaderNotifications />
    </Box>
  );
}

export default HeaderButtons;
