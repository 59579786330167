import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Custom ProtectedRoute component
function ProtectedRoute({ children }) {
  const location = useLocation();
  // const user = localStorage.getItem("accessToken");
  const authUser = useSelector((x) => x.user);
  const PUBLIC_ROUTES = ["/", "/404",  "/forgetPassword", "/resetpassword"];

  const isPublicPage = PUBLIC_ROUTES.includes(location.pathname);
  if (!authUser.accessToken && !isPublicPage) {
    // Redirect the user to a login page or a specific route
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  // Render the protected component if the user is authenticated
  return children;
}


function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ProtectedRoute>
        {content}
        </ProtectedRoute>
       
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
