import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
  tax: [],
  annuaire : [],
  selected_tax: null,
  loading: false,
  isSuccess: false,
  errors: [],
  recordsTotal: 0
};

export const fetchTax = createAsyncThunk(
  'tax/fetchTax',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/tax', { params });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTaxAnnuaire = createAsyncThunk(
  'tax/fetchTaxAnnuaire',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/tax/annuaire', { params });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTax = createAsyncThunk(
  'tax/addTax',
  async (taxData, { rejectWithValue, dispatch }) => {
    try {
      const params = {
        country : taxData.country,
        value: taxData.value,
        description: taxData.description,
        active : taxData.active
      };
      const response = await api.post('/tax/add', params);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTaxById = createAsyncThunk(
  'tax/getTaxById',
  async (taxId, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/tax/${taxId}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTaxById = createAsyncThunk(
  'tax/updateTaxById',
  async (updatedTax, { rejectWithValue, dispatch }) => {
    try {
      // eslint-disable-next-line camelcase
      const { id,country, value, description, active } =
      updatedTax;

      // eslint-disable-next-line camelcase
      const params = {
        id,
        country,
        value,
        description,
        active: Boolean(active), // Convertissez active en booléen
      };
      const response = await api.put(`/tax/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTax.pending]: (state) => {
      state.loading = true;
    },
    [fetchTax.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.tax = payload.data || [];
      state.recordsTotal = payload.recordsTotal || 0;
      state.loading = false;
    },
    [fetchTax.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [fetchTaxAnnuaire.pending]: (state) => {
      state.loading = true;
    },
    [fetchTaxAnnuaire.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.annuaire = payload.data || [];
      state.recordsTotal = payload.recordsTotal || 0;
      state.loading = false;
    },
    [fetchTaxAnnuaire.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addTax.pending]: (state) => {
      state.loading = true;
    },
    [addTax.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newTax = { ...payload, _DT_RowId: payload._id }; 
      state.tax.push(newTax);
      state.loading = false;
    },
    [addTax.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateTaxById.pending]: (state) => {
      state.loading = true;
    },
    [updateTaxById.fulfilled]: (state, { payload }) => {
      // Mettez à jour l'abonnement correspondant
      state.tax = state.tax.map((tax) =>
        tax._id === payload._id
          ? { ...tax, ...payload }
          : tax
      );
      state.loading = false;
    },
    [updateTaxById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [getTaxById.pending]: (state) => {
      state.loading = true;
    },
    [getTaxById.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selected_tax = payload;
      state.loading = false;
    },
    [getTaxById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
  }
});

export default taxSlice.reducer;
