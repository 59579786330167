import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {logout} from './authSlice';
import api from './config'; // Import the configured Axios instance

const initialState = {
  suggestions: [],
  all : [],
  recordsTotal : 0,
  selected_suggestions: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: []
};

export const fetchSuggestionsAll = createAsyncThunk(
  'suggestions/fetchSuggestionsAll',
  async (_, {rejectWithValue, dispatch}) => {

    try {
      const response = await api.get('/Suggestions/all_');
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSuggestions = createAsyncThunk(
  'suggestions/fetchSuggestions',
  async (params, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/Suggestions', {params});
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSuggestionById = createAsyncThunk(
  'suggestions/getSuggestionById',
  async (serviceId, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get(`/Suggestions/${serviceId}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSuggestions = createAsyncThunk(
  'suggestions/deleteSuggestion',
  async (serviceId, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.delete(`/Suggestions/${serviceId}`);

      dispatch(fetchSuggestions());
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSuggestion = createAsyncThunk(
  'suggestions/updateSuggestion',
  async (id, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.put(`/Suggestions/${id}`);
      dispatch(fetchSuggestions());
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const servicesSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSuggestions.pending]: (state) => {
      state.loading = true;
    },
    [fetchSuggestions.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.suggestions = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;
      state.loading = false;
    },
    
    [fetchSuggestions.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },

    [fetchSuggestionsAll.pending]: (state) => {
      state.loading = true;
    },
    [fetchSuggestionsAll.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.suggestions = payload?.data || [];
      state.recordsTotal = payload.recordsTotal || 0;
      state.loading = false;
    },
    [fetchSuggestionsAll.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },

    [deleteSuggestions.pending]: (state) => {
      state.loading = true;
    },
    [deleteSuggestions.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteSuggestions.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateSuggestion.pending]: (state) => {
      state.loading = true;
    },
    [updateSuggestion.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [updateSuggestion.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});

export default servicesSlice.reducer;
