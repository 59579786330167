/* eslint-disable no-unused-vars */
import {useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, FormControlLabel, Switch, MenuItem , TextField, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import { updateSuggestion } from 'src/redux/suggestionsSlice';
import { ToastContainer, toast } from 'react-toastify';

const CustomSuccessIconComponent = () => (
  <img
    src={require("../../../../assets/images/favicon.png")}
    alt="Custom Success Icon"
    style={{ width: '24px', height: '24px' }} // Ajustez la taille de l'image selon vos besoins
  />
);
const ConfirmationModal = ({ open, onClose,idSuggestion }) => {
  const loading = useSelector((x) => x.suggestions.loading);
  const dispatch=useDispatch();
  const handleUpdate = () => {
    dispatch(updateSuggestion(idSuggestion));
    
    onClose();
    toast.success("Suggestion accepted successfully !",{
      progressClassName: 'toast-progress-custom',
      icon: <CustomSuccessIconComponent />
    })
};
  return (
    <Dialog open={open} onClose={onClose}>
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 220,
        paddingTop: 24,
        border: 'none',
        borderRadius: 11,
        backgroundColor: '#121A25'
      }}
      display={'block'}
      direction={'column'}
    >
      <DialogTitle
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'primary'}
      >
        Accept suggestion service
      </DialogTitle>
      {loading ? (
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          startIcon={<RefreshTwoToneIcon />}
        />
      ) : (
        <DialogContent>
          <Typography variant="body3" color="white" marginTop={2}>
            Are you sure you want to accept this suggestion ?
          </Typography>
        </DialogContent>
      )}
      <Grid display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <DialogActions>
          <Button
            style={{marginTop: -100}}
            sx={{
              borderRadius: '30px',
              border: '1px solid white',
              color: 'white'
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            style={{marginTop: -100}}
            variant="contained"
            sx={{borderRadius: '30px'}}
            color="primary"
            onClick={handleUpdate}
          >
            Yes, Confirm
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  </Dialog>
  );
};

export default ConfirmationModal;
