import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  frequentQuestions: [],
  recordsTotal: 0,

  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchFrequentQuestions = createAsyncThunk(
  'frequentquestion/fetchFrequentQuestions',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/crm-module/frequentQuestion', {
        params
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const addFrequentQuestions = createAsyncThunk(
  'frequentquestion/addFrequentQuestions',
  async (frequentquestionData, { rejectWithValue, dispatch }) => {
    try {
      const bodyData = {
        question: frequentquestionData.question,
        response: frequentquestionData.response
      };
      const response = await api.post(
        '/crm-module/frequentQuestion/add',
        bodyData
      );
      dispatch(fetchFrequentQuestions());

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateFrequentquestion = createAsyncThunk(
  'frequentquestion/updateFrequentquestion',
  async (updateFrequentquestion, { rejectWithValue, dispatch }) => {
    try {
      const { id, question, response } = updateFrequentquestion;
      let params = { question, response };
      const responseApi = await api.put(
        `/crm-module/frequentQuestion/${id}`,
        params
      );
      return responseApi.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const archivefrequentQuestion = createAsyncThunk(
  'frequentquestion/archivefrequentQuestion',
  async (frequentQuestion, { rejectWithValue, dispatch }) => {
    try {
      const id = frequentQuestion.DT_RowId;
      const data = { active: !frequentQuestion.active };
      //  const active = false;
      const response = await api.put(
        `/crm-module/frequentQuestion/archive/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const FrequentQuestionSlice = createSlice({
  name: 'frequentquestion',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchFrequentQuestions.pending]: (state) => {
      state.loading = true;
    },
    [fetchFrequentQuestions.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.frequentQuestions = payload?.data || {};
      state.loading = false;
    },
    [fetchFrequentQuestions.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addFrequentQuestions.pending]: (state) => {
      state.loading = true;
    },
    [addFrequentQuestions.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newfrequentQuestions = { ...payload };
      state.frequentQuestions.push(newfrequentQuestions);
      state.loading = false;
    },
    [addFrequentQuestions.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateFrequentquestion.pending]: (state) => {
      state.loading = true;
    },
    [updateFrequentquestion.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.frequentQuestions = state.frequentQuestions.map(
        (frequentQuestion) =>
          frequentQuestion.DT_RowId === payload._id
            ? { ...payload, DT_RowId: payload._id }
            : frequentQuestion
      );
      state.loading = false;
    },
    [updateFrequentquestion.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [archivefrequentQuestion.pending]: (state) => {
      state.loading = true;
    },
    [archivefrequentQuestion.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.frequentQuestions = state.frequentQuestions.map(
        (frequentQuestion) =>
          frequentQuestion.DT_RowId === payload._id
            ? { ...payload, DT_RowId: payload._id }
            : frequentQuestion
      );
      state.loading = false;
    },
    [archivefrequentQuestion.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default FrequentQuestionSlice.reducer;
