import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  footer: {},
  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchFooter = createAsyncThunk(
  'footer/fetchFooter',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/crm-module/footer');
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateFooter = createAsyncThunk(
  'footer/updateFooter',
  async (updatedFooter, { rejectWithValue, dispatch }) => {
    try {
      // eslint-disable-next-line camelcase
      const { id, title } = updatedFooter;

      // eslint-disable-next-line camelcase
      const params = { title };
      const response = await api.put(`/crm-module/footer/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const FooterSlice = createSlice({
  name: 'footer',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchFooter.pending]: (state) => {
      state.loading = true;
    },
    [fetchFooter.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.footer = payload || {};
      state.loading = false;
    },
    [fetchFooter.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateFooter.pending]: (state) => {
      state.loading = true;
    },
    [updateFooter.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.footer = payload || {};
      state.loading = false;
    },
    [updateFooter.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default FooterSlice.reducer;
