import { Typography,  Grid } from '@mui/material';


import { useSelector } from 'react-redux';



function PageHeaderAnnuaire() {
  const authUser = useSelector((x) => x.user);
  const user = {
    name: (authUser?.profile?.firstname && authUser?.profile?.lastname) ?
      `${authUser.profile.firstname} ${authUser.profile.lastname}` :
      authUser?.profile?.email || '',
    avatar: '/static/images/avatars/1.jpg'
  };


  

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
            Tax Book
        </Typography>
        <Typography variant="subtitle2">
          {user.name}, these are  the current tax value by country
        </Typography>
      </Grid>

      
    </Grid>
  );
}

export default PageHeaderAnnuaire;
