import {useContext, useEffect, useRef, useState} from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Tooltip,
  IconButton,
  Badge,
  Typography
} from '@mui/material';
import {NavLink as RouterLink} from 'react-router-dom';
import {SidebarContext} from 'src/contexts/SidebarContext';
import {useNavigate} from 'react-router-dom';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import BusinessIcon from '@mui/icons-material/Business';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AssistantIcon from '@mui/icons-material/Assistant';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LogoutIcon from '@mui/icons-material/Logout';
import PolicyIcon from '@mui/icons-material/Policy';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/authSlice';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LinkIcon from '@mui/icons-material/Link';
import DvrIcon from '@mui/icons-material/Dvr';
import CopyrightIcon from '@mui/icons-material/Copyright';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { AddCardRounded, ContactsOutlined, DensitySmallOutlined, FileCopyTwoTone, InfoOutlined, PeopleAltOutlined, PersonSearch } from '@mui/icons-material';
const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({theme}) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 0.2px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };
  const {closeSidebar} = useContext(SidebarContext);

  const data = useSelector((x) => x?.suggestions?.suggestions || []);

  const [count, setCount] = useState(0);

  // Appel de la fonction pour mettre à jour le compteur dès le chargement initial
  useEffect(() => {
    data.map((item, index) => {
      if (!item.isAccepted) {
        setCount(index);
      }
    });
  }, [count, data]);

  return (
    <>
      <MenuWrapper>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
            Dashboard
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/tasks"
                  startIcon={<BrightnessLowTwoToneIcon 
                    style={{color:"#18E68E"}}
                  />}
                >
                  General
                </Button>
              </ListItem>
              
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/companies"
                  startIcon={<BusinessIcon 
                    style={{color:"#18E68E"}}

                  />}
                >
                  Companies
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/BusinessArea"
                  startIcon={<AddBusinessIcon 
                    style={{color:"#18E68E"}}

                  />}
                >
                  Business Area
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/services"
                  startIcon={<DesignServicesIcon 
                    style={{color:"#18E68E"}}

                  />}
                >
                  Services
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/suggestions"
                  startIcon={<AssistantIcon 
                    style={{color:"#18E68E"}}

                  />}
                >
                  Suggestions
                  <Tooltip
                    arrow
                    title="Untreated"
                    color="error"
                    style={{marginLeft: 10}}
                  >
                    <Button
                      style={{backgroundColor:'transparent'}}
                      color='warning'
                      variant='contained'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/management/suggestions/not_treated"
                    >
                      <Badge
                        style={{marginRight:-14, width: 100, alignItems:"center", display:'flex', justifyContent:"center"}}
                        className="text-danger "
                        badgeContent={count}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      ></Badge>
                    </Button>
                  </Tooltip>
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/tax"
                  startIcon={<FileCopyTwoTone
                    style={{color:"#18E68E"}}

                  />}
                >
                  Tax
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Memberships & Payments
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/subscriptions"
                  startIcon={<AddCardRounded 
                    style={{color:"#18E68E"}}

                  />}
                >
                  Subscriptions
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/payments"
                  startIcon={<AddCardRounded 
                    style={{color:"#18E68E"}}

                  />}
                >
                  Payments
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Customers
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/customers"
                  startIcon={<PersonSearch 
                    style={{color:"#18E68E"}}

                  />}
                >
                  Customers
                </Button>
              </ListItem>
            </List>

          </SubMenuWrapper>
          
                  <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              CRM
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/CRM/PrivacyPolicy"
                  
                  startIcon={<PolicyIcon  style={{color:"#18E68E"}}/>}
                >
                  Privacy Policy
                </Button>
              </ListItem>
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/SocialLink"
                    startIcon={<PeopleAltOutlined style={{color:"#18E68E"}}/>}
                  >
                    Social Links
                  </Button>
                </ListItem>
              }
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/TermOfUse"
                    startIcon={<DensitySmallOutlined style={{color:"#18E68E"}}/>}
                  >
                    Term of use
                  </Button>
                </ListItem>
              }

              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/AboutUs"
                    startIcon={<InfoOutlined style={{color:"#18E68E"}}/>}
                  >
                    About Us
                  </Button>
                </ListItem>
              }
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/ContactUs"
                    startIcon={<ContactsOutlined style={{color:"#18E68E"}}/>}
                  >
                    Contacts
                  </Button>
                </ListItem>
              }
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/Footer"
                    startIcon={<CopyrightIcon style={{color:"#18E68E"}}/>}
                  >
                    Footer
                  </Button>
                </ListItem>
              }
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/Map"
                    startIcon={<LocationOnIcon style={{color:"#18E68E"}}/>}
                  >
                    Location
                  </Button>
                </ListItem>
              }
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/FrequentQuestions"
                    startIcon={<HelpCenterIcon style={{color:"#18E68E"}}/>}
                  >
                    Frequent Questions
                  </Button>
                </ListItem>
              }
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/UsefulLinks"
                    startIcon={<LinkIcon style={{color:"#18E68E"}}/>}
                  >
                    Useful Links
                  </Button>
                </ListItem>
              }
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/CRM/AppServices"
                    startIcon={<DvrIcon style={{color:"#18E68E"}}/>}
                  >
                    Application Services
                  </Button>
                </ListItem>
              }
            </List>
          </SubMenuWrapper>
        </List>
          <SubMenuWrapper>

          </SubMenuWrapper>

        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Accounts
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/profile/details"
                  startIcon={<AccountCircleTwoToneIcon 
                    style={{color:"#18E68E"}}

                  />}
                >
                  User Profile
                </Button>
              </ListItem>
              {
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/profile/settings"
                    startIcon={<DisplaySettingsTwoToneIcon 
                      style={{color:"#18E68E"}}

                    />}
                  >
                    Account Settings
                  </Button>
                </ListItem>
              }
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
