// Importez useState pour gérer l'état des suggestions regroupées par utilisateur
import React, {useEffect, useState} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {useDispatch} from 'react-redux';
import Label from 'src/components/Label';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {useSelector} from 'react-redux';
import AssistantIcon from '@mui/icons-material/Assistant';
import {
  Tooltip,
  InputAdornment,
  TextField,
  useTheme,
  Box,
  FormControl,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  Button,
  Divider
} from '@mui/material';
import {fetchSuggestions} from 'src/redux/suggestionsSlice';
import ConfirmationModal from './ConfirmationModal';
import ConfirmationDeleteModal from './ConfirmationDeleteModal';
import ToastSuccess from '../../../../components/ToastSuccess/ToastSuccess';
import 'react-toastify/dist/ReactToastify.css';
import {EditTwoTone} from '@mui/icons-material';

const SuggestionsNotTreatedTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const recordsTotal = useSelector((x) => x.suggestions.recordsTotal);
  const [serviceIdToSelect, setserviceIdToSelect] = useState(null);
  const [acceptConfirmation, setAcceptConfirmation] = useState(false);
  const data = useSelector((x) => x.suggestions.suggestions || []);
  const loading = useSelector((x) => x.suggestions.loading);
  const [suggestionIdToSelect, setsuggestionIdToSelect] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [draw, setDraw] = useState(1);
  const [searchValue, setSearchValue] = useState(''); // State for search value
  const [sortColumn, setSortColumn] = useState(0); // State for sort column
  const [sortDirection, setSortDirection] = useState('asc'); // State for sort direction
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchValue, sortColumn, sortDirection, page, limit]);

  const fetchData = async () => {
    const queryParams = {
      draw,
      columns: [
        {
          data: 'name',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'createdAt',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'added_by',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'business_area',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'active',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'isAccepted',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        }
      ],
      order: [{column: sortColumn, dir: sortDirection}],
      start: page * limit,
      length: limit,
      search: {value: searchValue, regex: false},
      _: Date.now()
    };
    dispatch(fetchSuggestions(queryParams));
    setDraw((prevDraw) => prevDraw + 1);
  };

  const getStatusLabel = (cryptoOrderStatus) => {
    const map = {
      true: {text: 'Accepted', color: 'success'},
      false: {text: 'Inaccepted', color: 'error'}
    };

    const {text, color} = map[cryptoOrderStatus];

    return <Label color={color}>{text}</Label>;
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  // Fonction pour regrouper les suggestions par l'utilisateur qui les a ajoutées
  const groupSuggestionsByUser = (suggestions) => {
    return suggestions.reduce((acc, suggestion) => {
      const addedBy =
        suggestion.added_by[0].lastname +
        ' ' +
        suggestion.added_by[0].firstname;
      if (!acc[addedBy]) {
        acc[addedBy] = [];
      }
      acc[addedBy].push(suggestion);
      return acc;
    }, {});
  };

  // Utilisez la fonction groupSuggestionsByUser pour regrouper les suggestions
  const groupedSuggestions = groupSuggestionsByUser(data);

  return (
    <Card>
      <ToastSuccess />
      <FormControl sx={{ml: 1, mt:1}}>
        <TextField
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      {loading ? null : Object.keys(groupedSuggestions).length === 0 ? (
        <TableRow style={{overflow:'hidden'}}>
          <TableCell colSpan={6} rowSpan={2} style={{textAlign: 'center'}}>
            <Typography variant="body1" color="text.primary">
              No Data Available
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        Object.entries(groupedSuggestions).map(([addedBy, suggestions]) => (
          <React.Fragment key={suggestions}>
            <Grid container marginTop={2} marginBottom={2} marginLeft={2}>
              <Grid
                item
                lg={12}
                display={'block'}
                justifyContent={'start'}
                alignItems={'start'}
              >
                <Grid display={'flex'}>
                  <Typography variant="h6" component="h6" gutterBottom>
                    Company{' '}
                  </Typography>
                  <Typography
                    style={{marginTop: 1, fontWeight: 'bold'}}
                    color={'primary'}
                  >
                    &nbsp; {addedBy}
                  </Typography>
                </Grid>

                <Divider style={{border: '1px solid #18e68e', width: 100}} />
              </Grid>
            </Grid>
            <Table>
              {
                data.length !== 0?

              
              <React.Fragment  key={addedBy}>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => handleSort(0)}>
                      Suggestion{' '}
                      {sortColumn === 0 ? (
                        sortDirection === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )
                      ) : (
                        <SwapVertIcon fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell onClick={() => handleSort(2)}>
                      Business Area{' '}
                      {sortColumn === 1 ? (
                        sortDirection === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )
                      ) : (
                        <SwapVertIcon fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell onClick={() => handleSort(1)}>
                      Created At{' '}
                      {sortColumn === 2 ? (
                        sortDirection === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )
                      ) : (
                        <SwapVertIcon fontSize="small" />
                      )}
                    </TableCell>

                    <TableCell onClick={() => handleSort(3)}>
                      Acceptation{' '}
                      {sortColumn === 3 ? (
                        sortDirection === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )
                      ) : (
                        <SwapVertIcon fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suggestions.map((item) => (
                    <React.Fragment key={item?.DT_RowId}>
                      {!item?.isAccepted && (
                        <TableRow hover >
                          <TableCell>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              <div
                                style={{display: 'flex', alignItems: 'center'}}
                              >
                                <AssistantIcon />
                                <span style={{marginLeft: '10px'}}>
                                  {' '}
                                  {item?.name}{' '}
                                </span>
                              </div>
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {item?.business_area}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {new Date(item.createdAt).toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {getStatusLabel(item?.isAccepted)}
                          </TableCell>

                          <TableCell align="justify">
                            <>
                              <Tooltip
                                title="Edit Suggestion"
                                disabled={false}
                                arrow
                              >
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setserviceIdToSelect(item?.DT_RowId);
                                    setAcceptConfirmation(true);
                                  }}
                                >
                                  <EditTwoTone fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {/* Vos autres actions */}
                            </>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </React.Fragment>
              : <p>false</p>}
            </Table>
          </React.Fragment>
        ))
      )}
      <Box p={2}>
        <TablePagination
          component="div"
          count={recordsTotal || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
      <ConfirmationModal
        open={acceptConfirmation}
        onClose={() => setAcceptConfirmation(false)}
        idSuggestion={serviceIdToSelect}
      />
      {deleteConfirmation ? (
        <ConfirmationDeleteModal
          open={deleteConfirmation}
          onClose={() => setDeleteConfirmation(false)}
          onConfirm={handleDelete}
        />
      ) : null}
    </Card>
  );
};

export default SuggestionsNotTreatedTable;
