/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, FormControlLabel, Switch, MenuItem , TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';



const ConfirmationDeleteModal = ({ open, onClose, onConfirm }) => {
  const dispatch = useDispatch();
  const [businessName, setBusinessName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const loading = useSelector((x) => x.suggestions.loading);


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Suggestion</DialogTitle>
      {loading ? 
      <LoadingButton
              loading={loading}
              variant="outlined"
              color="primary"
              startIcon={<RefreshTwoToneIcon />}
             />
        :
      <DialogContent>
      {errorMessage && (
          <Typography variant="body2" color="error" marginTop={1}>
            {errorMessage}
          </Typography>
        )}
        
        <Typography variant="body3" color="white" marginTop={2}>
        Are you sure you want to delete a suggestion ?
        </Typography>

      </DialogContent>
        }
      <DialogActions>
        <Button onClick={onConfirm}>Delete</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDeleteModal;
