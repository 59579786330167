import { DesktopMacOutlined} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
function Website() {
  return (
    <Tooltip arrow title="Notifications">
    <IconButton
     sx={{
      width:{xs: "5px",sm:"auto"}
    }}
    color="primary" >
    
        <DesktopMacOutlined 
        sx={{
          width:{xs: "20px" ,sm:"auto "}
        }}
        />
    </IconButton>
  </Tooltip>
  );
}

export default Website;
