/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';

import SearchIcon from '@mui/icons-material/Search';
/* import { deleteService } from 'src/redux/servicesSlice'; */
import {useDispatch} from 'react-redux';
import Label from 'src/components/Label';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
/* import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'; */
import {useSelector} from 'react-redux';
/* import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'; */
import {
  InputAdornment,
  TextField,
  useTheme,
  Box,
  FormControl,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import {fetchAbonnements} from 'src/redux/subscriptionSlice';
import {CardMembership, EditTwoTone} from '@mui/icons-material';
import {RiEyeFill, RiMastercardLine} from 'react-icons/ri';
import {fetchPayments} from 'src/redux/PaymentSlice';
import EditPayment from './EditPayment';
import ViewPayment from './ViewPayment';
import StripePayment from './EditPayment';
// import AbonnementUpdateModal from './AbonnementUpdateModal';

const PaymentTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const recordsTotal = useSelector((x) => x.payment.recordsTotal);
  const data = useSelector((x) => x.payment.payments || []); // Utilisez 'abonnement' au lieu de 'plans'
  const loading = useSelector((x) => x.payment.loading); // Utilisez 'abonnement' au lieu de 'plans'

  const [draw, setDraw] = useState(1);
  const [searchValue, setSearchValue] = useState(''); // State for search value
  const [sortColumn, setSortColumn] = useState(0); // State for sort column
  const [sortDirection, setSortDirection] = useState('asc'); // State for sort direction
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const [updateModal, setUpdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [stateModal, setStateModal] = useState(false);

  const [paymentIdToSelect, setPaymentIdToSelect] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchValue, sortColumn, sortDirection, page, limit]);

  const fetchData = async () => {
    const queryParams = {
      draw,
      columns: [
        {
          data: 'customer',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: searchValue, regex: true}
        },

        {
          data: 'abonnement',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'brand',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'price_net',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },

        {
          data: 'createdAt',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'updatedAt',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'status',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        },
        {
          data: 'refund_status',
          name: '',
          searchable: true,
          orderable: true,
          search: {value: '', regex: true}
        }
      ],
      order: [{column: sortColumn, dir: sortDirection}],
      start: page * limit,
      length: limit,
      search: {value: searchValue, regex: false},
      _: Date.now()
    };

    dispatch(fetchPayments(queryParams));

    setDraw((prevDraw) => prevDraw + 1);
  };

  const getStatusLabelByValue = (cryptoOrderStatus) => {
    const map = {
      verified: {
        text: 'Verified',
        color: '#39b36d'
      },
      declined: {
        text: 'Declined',
        color: 'error'
      }
    };

    const {text, color} = map[cryptoOrderStatus];

    return (
      <Label style={{width: '100px'}} color={color}>
        {text}
      </Label>
    );
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // sendPaginationToParent();
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    // sendPaginationToParent();
  };

  const getStatusLabel = (cryptoOrderStatus) => {
    const map = {
      true: {
        text: 'Active',
        color: 'primary'
      },
      false: {
        text: 'Inactive',
        color: 'error'
      }
    };

    const {text, color} = map[cryptoOrderStatus];

    return <Label color={color}>{text}</Label>;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedDateTime = `${formattedDate} - ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }

  return (
    <Card>
      <FormControl sx={{ml: 1, mt: 1, mb: 1}}>
        <TextField
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSort(0)}>
              Customer{' '}
              {sortColumn === 0 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(1)}>
              Plan
              {sortColumn === 4 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(2)}>
              Brand
              {sortColumn === 4 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(3)}>
              Price {'($)'}
              {sortColumn === 4 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>

            <TableCell onClick={() => handleSort(4)}>
              Created At{' '}
              {sortColumn === 2 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(5)}>
              Updated At{' '}
              {sortColumn === 3 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(6)}>
              Status{' '}
              {sortColumn === 7 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell onClick={() => handleSort(7)}>
              Refund{' '}
              {sortColumn === 7 ? (
                sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <SwapVertIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? null : data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} rowSpan={2} style={{textAlign: 'center'}}>
                <Typography variant="body1" color="text.primary">
                  No Data Available
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            data.map((item) => (
              <TableRow hover key={item?.DT_RowId}>
                <TableCell>
                  {item?.customer.firstname?.charAt(0).toUpperCase() +
                    item?.customer?.firstname?.slice(1)}{' '}
                  {item.customer.lastname?.toUpperCase()}
                </TableCell>
                <TableCell>
                  <Label color="primary">{item?.abonnement?.title}</Label>
                </TableCell>
                <TableCell>
                  {item?.brand === 'visa' && (
                    <Label color="info">{item?.brand}</Label>
                  )}
                  {item?.brand === 'mastercard' && (
                    <Label className={'text-center'} color="error">
                      {item?.brand}
                    </Label>
                  )}
                </TableCell>
                <TableCell>
                  <Label className={'text-center'} color="primary">
                    {isNaN(parseFloat(item?.price_net))
                      ? 'Invalid Price'
                      : parseFloat(item?.price_net).toFixed(2)}{' '}
                    {' $ '}
                  </Label>
                </TableCell>
                <TableCell>{formatDate(item?.createdAt)}</TableCell>
                <TableCell>{formatDate(item?.updatedAt)}</TableCell>
                <TableCell>
                  {item?.status === 'active' && (
                    <Label color="primary">{item?.status + ''}</Label>
                  )}
                  {item?.status === 'canceled' && (
                    <Label color="error">{item?.status + ''}</Label>
                  )}
                </TableCell>

                <TableCell>
                  {item?.refund_status === 'not refunded' && (
                    <Label color="info">{item?.refund_status + ''}</Label>
                  )}
                  {item?.refund_status === 'pending' && (
                    <Label color="info">{item?.refund_status + ''}</Label>
                  )}
                  {item?.refund_status === 'refunded' && (
                    <Label color="primary">{item?.refund_status + ''}</Label>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Payment Edit" arrow>
                    <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter
                        },
                        color: theme.palette.primary.main
                      }}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setPaymentIdToSelect(item.DT_RowId);
                        setUpdateModal(true);
                      }}
                    >
                      <EditTwoTone fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box p={2}>
        <TablePagination
          component="div"
          count={recordsTotal || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
      {updateModal ? (
        <StripePayment
          open={updateModal}
          selected_payment={paymentIdToSelect}
          onClose={() => setUpdateModal(false)}
        />
      ) : null}
    </Card>
  );
};

export default PaymentTable;
