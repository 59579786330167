import { Typography,  Grid, Button } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSelector } from 'react-redux';
import { useState } from 'react';



function PageHeaderCustomers() {
  const authUser = useSelector((x) => x.user);
  const user = {
    name: (authUser?.profile?.firstname && authUser?.profile?.lastname) ?
      `${authUser.profile.firstname} ${authUser.profile.lastname}` :
      authUser?.profile?.email || '',
    avatar: '/static/images/avatars/1.jpg'
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
            Customers
        </Typography>
        <Typography variant="subtitle2">
          {user.name}, these are  the customers of the system
        </Typography>
      </Grid>
    
    </Grid>
  );
}

export default PageHeaderCustomers;
