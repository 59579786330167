import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  UsefulLinks: [],
  recordsTotal: 0,

  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchUsefulLinks = createAsyncThunk(
  'usefullink/fetchUsefulLinks',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/crm-module/usefulLink', { params });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const addUsefulLinks = createAsyncThunk(
  'usefullink/addUsefulLinks',
  async (usefulLinkdata, { rejectWithValue, dispatch }) => {
    try {
      const bodyData = {
        title: usefulLinkdata.title,
        link: usefulLinkdata.link
      };
      const response = await api.post('/crm-module/usefulLink/add', bodyData);

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUsefulLinks = createAsyncThunk(
  'usefullink/updateUsefulLinks',
  async (updatedUseful, { rejectWithValue, dispatch }) => {
    try {
      const { id, title, link } = updatedUseful;

      const params = { title, link };

      const response = await api.put(`/crm-module/usefulLink/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const archiveUsefulLinks = createAsyncThunk(
  'usefullink/archiveUsefulLinks',
  async (usefulLink, { rejectWithValue, dispatch }) => {
    try {
      const id = usefulLink.DT_RowId;
      const data = { active: !usefulLink.active };
      const response = await api.put(
        `/crm-module/usefulLink/archive/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const UsefulLinksSlice = createSlice({
  name: 'usefullink',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchUsefulLinks.pending]: (state) => {
      state.loading = true;
    },
    [fetchUsefulLinks.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.UsefulLinks = payload?.data;
      state.recordsTotal = payload?.recordsTotal || 0;

      state.loading = false;
    },
    [fetchUsefulLinks.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateUsefulLinks.pending]: (state) => {
      state.loading = true;
    },
    [updateUsefulLinks.fulfilled]: (state, { payload }) => {
      state.UsefulLinks = state.UsefulLinks.map((UsefulLink) =>
        UsefulLink.DT_RowId === payload._id
          ? { ...payload, DT_RowId: payload._id }
          : UsefulLink
      );
      state.loading = false;
    },
    [updateUsefulLinks.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addUsefulLinks.pending]: (state) => {
      state.loading = true;
    },
    [addUsefulLinks.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newUsefulLink = { ...payload };
      state.UsefulLinks.push(newUsefulLink);
      state.loading = false;
    },
    [addUsefulLinks.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [archiveUsefulLinks.pending]: (state) => {
      state.loading = true;
    },
    [archiveUsefulLinks.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.UsefulLinks = state.UsefulLinks.map((UsefulLink) =>
        UsefulLink.DT_RowId === payload._id
          ? { ...payload, DT_RowId: payload._id }
          : UsefulLink
      );
      state.loading = false;
    },
    [archiveUsefulLinks.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default UsefulLinksSlice.reducer;
