import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import servicesSlice from './servicesSlice';
import companiesSlice from './companiesSlice';
import businessAreaSlice from './businessAreaSlice';
import forgotPasswordSlice from './forgotPasswordSlice';
import suggestionsSlice from './suggestionsSlice';
import subscriptionSlice from './subscriptionSlice';
import taxSlice from './taxSlice';
import customerSlice from './customerSlice';
import PrivacyPolicySlice from './PrivacyPolicySlice';
import socialLinksSlice from './socialLinksSlice';
import termOfUseSlice from './termOfUseSlice';
import AboutUsSlice from './AboutUsSlice';
import ContactUsSlice from './ContactUsSlice';
import FooterSlice from './FooterSlice';
import MapSlice from './MapSlice';
import FrequentQuestionSlice from './FrequentQuestionSlice';
import UsefulLinksSlice from './UsefulLinksSlice';
import AppServicesSlice from './AppServicesSlice';
import PaymentSlice from './PaymentSlice';

export const store = configureStore({
  reducer: {
    user: authSlice,
    services: servicesSlice,
    companies: companiesSlice,
    businessAreas:businessAreaSlice,
    forgotPassword: forgotPasswordSlice,
    suggestions: suggestionsSlice,
    abonnement : subscriptionSlice,
    payment : PaymentSlice,
    tax : taxSlice,
    customers : customerSlice,
    privacyPolicy: PrivacyPolicySlice,
    socialLinks: socialLinksSlice,
    termOfUse: termOfUseSlice,
    aboutUs: AboutUsSlice,
    contactUs: ContactUsSlice,
    Footer: FooterSlice,
    map: MapSlice,
    appService: AppServicesSlice,
    frequentQuestion: FrequentQuestionSlice,
    usefulLink: UsefulLinksSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  }
);
