import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  map: {},
  adresse: {},
  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchMapDetails = createAsyncThunk(
  'map/fetchMapDetails',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/crm-module/map');
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchadressDetails = createAsyncThunk(
  'map/fetchadressDetails',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/crm-module/adresse');
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateMap = createAsyncThunk(
  'map/updateMap',
  async (updatedmap, { rejectWithValue, dispatch }) => {
    try {
      // eslint-disable-next-line camelcase
      const { id, long, lat } = updatedmap;

      // eslint-disable-next-line camelcase
      const params = { long, lat };
      const response = await api.put(`/crm-module/map/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateadresse = createAsyncThunk(
  'map/updateadresse',
  async (updateAdresse, { rejectWithValue, dispatch }) => {
    try {
      console.log(updateAdresse);
      // eslint-disable-next-line camelcase
      const { id, location, number, email } = updateAdresse;

      // eslint-disable-next-line camelcase
      const params = { location, number, email };
      const response = await api.put(`/crm-module/adresse/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const MapSlice = createSlice({
  name: 'map',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchMapDetails.pending]: (state) => {
      state.loading = true;
    },
    [fetchMapDetails.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.map = payload || {};
      state.loading = false;
    },
    [fetchMapDetails.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [fetchadressDetails.pending]: (state) => {
      state.loading = true;
    },
    [fetchadressDetails.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.adresse = payload || {};
      state.loading = false;
    },
    [fetchadressDetails.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateMap.pending]: (state) => {
      state.loading = true;
    },
    [updateMap.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.map = payload || {};
      state.loading = false;
    },
    [updateMap.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateadresse.pending]: (state) => {
      state.loading = true;
    },
    [updateadresse.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.adresse = payload || {};
      state.loading = false;
    },
    [updateadresse.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default MapSlice.reducer;
