/* eslint-disable no-unused-vars */
import {React, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {AddTwoTone, Circle, RemoveTwoTone} from '@mui/icons-material';
import {addAbonnement} from 'src/redux/subscriptionSlice';

const SubscriptionCreationModal = ({open, onClose}) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [little_description, setLittle_description] = useState('');
  const [price, setPrice] = useState(0);
  const [details, setDetails] = useState([]);
  const [active, setActive] = useState('');
  const [type, setType] = useState('');
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [quarterlyDiscount, setQuarterlyDiscount] = useState(0);
  const [semiAnnualDiscount, setSemiAnnualDiscount] = useState(0);
  const [annualDiscount, setAnnualDiscount] = useState(0);
  const [monthly, setMonthly] = useState('Monthly');
  const [quarterly, setQuarterly] = useState('Quarterly');
  const [semiAnnual, setSemiAnnual] = useState('Semi-Annual');
  const [annual, setAnnual] = useState('Annual');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    if (
      !title ||
      !little_description ||
      price < 0 ||
      details.length === 0 ||
      type === ''
    ) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    }
    const subscriptionData = {
      title,
      little_description,
      price: parseFloat(price), // Convertir en nombre
      details,
      active,
      type,
      monthlyDiscount: parseInt(monthlyDiscount),
      quarterlyDiscount: parseInt(quarterlyDiscount),
      semiAnnualDiscount: parseInt(semiAnnualDiscount),
      annualDiscount: parseInt(annualDiscount),
      monthly: type === 'Pay' ? monthly : '',
      quarterly: type === 'Pay' ? quarterly : '',
      semiAnnual: type === 'Pay' ? semiAnnual : '',
      annual: type === 'Pay' ? annual : ''
    };

    dispatch(addAbonnement(subscriptionData))
      .unwrap()
      .then(() => {
        setTitle('');
        setLittle_description('');
        setPrice(0);
        setDetails([]);
        setType('');
        setMonthlyDiscount(0);
        setQuarterlyDiscount(0);
        setSemiAnnualDiscount(0);
        setAnnualDiscount(0);
        setMonthly('');
        setQuarterly('');
        setSemiAnnual('');
        setAnnual('');
        setErrorMessage('');
        setActive(true);
        onClose();
      })
      .catch((error) => {
        setErrorMessage(error?.message);
      });
  };

  const handleAddField = () => {
    setDetails([...details, '']);
  };

  const handleFieldChange = (index, value) => {
    const newFields = [...details];
    newFields[index] = value;
    setDetails(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = [...details];
    newFields.splice(index, 1);
    setDetails(newFields);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create subscription</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Typography variant="body2" color="error" marginTop={1}>
            {errorMessage}
          </Typography>
        )}

        <TextField
          label="Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Description"
          value={little_description}
          onChange={(e) => {
            setLittle_description(e.target.value);
          }}
          fullWidth
          margin="normal"
          multiline={true}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Type</InputLabel>
          <Select
            label="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="Free">Free</MenuItem>
            <MenuItem value="Pay">Pay</MenuItem>
          </Select>
        </FormControl>

        {type === 'Free' ? (
          <></>
        ) : (
          <TextField
            label="Price"
            type="number"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            fullWidth
            margin="normal"
          />
        )}

        <Grid
          container
          spacing={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Monthly</InputLabel>
                <Select
                  selected={'Monthly'}
                  label="Monthly"
                  value={monthly}
                  onChange={(e) => setMonthly(e.target.value)}
                >
                  <MenuItem value="Monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Quarterly</InputLabel>
                <Select
                  selected="Quarterly"
                  label="Quarterly"
                  value={quarterly}
                  onChange={(e) => setQuarterly(e.target.value)}
                >
                  <MenuItem value="Quarterly">Quarterly</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Semi-Annual</InputLabel>
                <Select
                  selected="Semi-Annual"
                  label="Semi-Annual"
                  value={semiAnnual}
                  onChange={(e) => setSemiAnnual(e.target.value)}
                >
                  <MenuItem value="Semi-Annual">Semi-Annual</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Annual</InputLabel>
                <Select
                  selected="Annual"
                  label="Annual"
                  value={annual}
                  onChange={(e) => setAnnual(e.target.value)}
                >
                  <MenuItem value="Annual">Annual</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Monthly Discount</InputLabel>
                <Select
                  label="Monthly Discount"
                  value={monthlyDiscount}
                  onChange={(e) => setMonthlyDiscount(e.target.value)}
                >
                  <MenuItem value={0}>0 %</MenuItem>
                  <MenuItem value={5}>5 %</MenuItem>
                  <MenuItem value={10}>10 %</MenuItem>
                  <MenuItem value={15}>15 %</MenuItem>
                  <MenuItem value={20}>20 %</MenuItem>
                  <MenuItem value={25}>25 %</MenuItem>
                  <MenuItem value={30}>30 %</MenuItem>
                  <MenuItem value={35}>35 %</MenuItem>
                  <MenuItem value={40}>40 %</MenuItem>
                  <MenuItem value={45}>45 %</MenuItem>
                  <MenuItem value={50}>50 %</MenuItem>
                  <MenuItem value={55}>55 %</MenuItem>
                  <MenuItem value={60}>60 %</MenuItem>
                  <MenuItem value={65}>65 %</MenuItem>
                  <MenuItem value={70}>70 %</MenuItem>
                  <MenuItem value={75}>75 %</MenuItem>
                  <MenuItem value={80}>80 %</MenuItem>
                  <MenuItem value={85}>85 %</MenuItem>
                  <MenuItem value={90}>90 %</MenuItem>
                  <MenuItem value={95}>95 %</MenuItem>
                  <MenuItem value={100}>100 %</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Quarterly Discount</InputLabel>
                <Select
                  label="Quarterly Discount"
                  value={quarterlyDiscount}
                  onChange={(e) => setQuarterlyDiscount(e.target.value)}
                >
                  <MenuItem value={0}>0 %</MenuItem>
                  <MenuItem value={5}>5 %</MenuItem>
                  <MenuItem value={10}>10 %</MenuItem>
                  <MenuItem value={15}>15 %</MenuItem>
                  <MenuItem value={20}>20 %</MenuItem>
                  <MenuItem value={25}>25 %</MenuItem>
                  <MenuItem value={30}>30 %</MenuItem>
                  <MenuItem value={35}>35 %</MenuItem>
                  <MenuItem value={40}>40 %</MenuItem>
                  <MenuItem value={45}>45 %</MenuItem>
                  <MenuItem value={50}>50 %</MenuItem>
                  <MenuItem value={55}>55 %</MenuItem>
                  <MenuItem value={60}>60 %</MenuItem>
                  <MenuItem value={65}>65 %</MenuItem>
                  <MenuItem value={70}>70 %</MenuItem>
                  <MenuItem value={75}>75 %</MenuItem>
                  <MenuItem value={80}>80 %</MenuItem>
                  <MenuItem value={85}>85 %</MenuItem>
                  <MenuItem value={90}>90 %</MenuItem>
                  <MenuItem value={95}>95 %</MenuItem>
                  <MenuItem value={100}>100 %</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Semi-Annual Discount</InputLabel>
                <Select
                  label="Semi-Annual Discount"
                  value={semiAnnualDiscount}
                  onChange={(e) => setSemiAnnualDiscount(e.target.value)}
                >
                  <MenuItem value={0}>0 %</MenuItem>
                  <MenuItem value={5}>5 %</MenuItem>
                  <MenuItem value={10}>10 %</MenuItem>
                  <MenuItem value={15}>15 %</MenuItem>
                  <MenuItem value={20}>20 %</MenuItem>
                  <MenuItem value={25}>25 %</MenuItem>
                  <MenuItem value={30}>30 %</MenuItem>
                  <MenuItem value={35}>35 %</MenuItem>
                  <MenuItem value={40}>40 %</MenuItem>
                  <MenuItem value={45}>45 %</MenuItem>
                  <MenuItem value={50}>50 %</MenuItem>
                  <MenuItem value={55}>55 %</MenuItem>
                  <MenuItem value={60}>60 %</MenuItem>
                  <MenuItem value={65}>65 %</MenuItem>
                  <MenuItem value={70}>70 %</MenuItem>
                  <MenuItem value={75}>75 %</MenuItem>
                  <MenuItem value={80}>80 %</MenuItem>
                  <MenuItem value={85}>85 %</MenuItem>
                  <MenuItem value={90}>90 %</MenuItem>
                  <MenuItem value={95}>95 %</MenuItem>
                  <MenuItem value={100}>100 %</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            {type === 'Pay' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Annual Discount</InputLabel>
                <Select
                  label="Annual Discount"
                  value={annualDiscount}
                  onChange={(e) => setAnnualDiscount(e.target.value)}
                >
                  <MenuItem value={0}>0 %</MenuItem>
                  <MenuItem value={5}>5 %</MenuItem>
                  <MenuItem value={10}>10 %</MenuItem>
                  <MenuItem value={15}>15 %</MenuItem>
                  <MenuItem value={20}>20 %</MenuItem>
                  <MenuItem value={25}>25 %</MenuItem>
                  <MenuItem value={30}>30 %</MenuItem>
                  <MenuItem value={35}>35 %</MenuItem>
                  <MenuItem value={40}>40 %</MenuItem>
                  <MenuItem value={45}>45 %</MenuItem>
                  <MenuItem value={50}>50 %</MenuItem>
                  <MenuItem value={55}>55 %</MenuItem>
                  <MenuItem value={60}>60 %</MenuItem>
                  <MenuItem value={65}>65 %</MenuItem>
                  <MenuItem value={70}>70 %</MenuItem>
                  <MenuItem value={75}>75 %</MenuItem>
                  <MenuItem value={80}>80 %</MenuItem>
                  <MenuItem value={85}>85 %</MenuItem>
                  <MenuItem value={90}>90 %</MenuItem>
                  <MenuItem value={95}>95 %</MenuItem>
                  <MenuItem value={100}>100 %</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>

        <FormControlLabel
          control={<Switch icon={<Circle style={{ border:"1px solid #13B871", borderRadius:100}}/>} checked={active} onChange={(e) => setActive(e.target.checked)} />}
          label="Status (Active/Inactive)"
        />

      {/*   <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          }
          label="Visibility"
        /> */}
        <Button style={{borderRadius: 30}} onClick={handleAddField}>
          <AddTwoTone /> Add Some Details
        </Button>
        <Grid container justifyContent="space-between" alignItems="center">
          {details.map((value, index) => (
            <Grid
              key={index}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid key={index} item lg={10} md={10} sm={10}>
                <TextField
                  label={`${index}. Detail`}
                  type="text"
                  value={value}
                  onChange={(e) => handleFieldChange(index, e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <Button color="error" onClick={() => handleRemoveField(index)}>
                  <RemoveTwoTone /> Remove
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          marginBottom: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          sx={{borderRadius: '30px', border: '1px solid white', color: 'white'}}
          onClick={onClose}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          sx={{borderRadius: '30px'}}
          onClick={handleSubmit}
        >
          Yes, Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionCreationModal;
