import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  PrivacyPolicy: {},
  //selected_PrivacyPolicy: null,
  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchPrivacyPolicy = createAsyncThunk(
  'privacypolicy/fetchPrivacyPolicy',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('crm-module/privacyPolicy');
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatePrivacyPolicy = createAsyncThunk(
  'privacypolicy/updatePrivacyPolicy',
  async (updatedpolicy, { rejectWithValue, dispatch }) => {
    try {
      // eslint-disable-next-line camelcase
      const { id, title, description } = updatedpolicy;

      // eslint-disable-next-line camelcase
      const params = { title, description };
      const response = await api.put(`/crm-module/privacyPolicy/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const privacyPolicySlice = createSlice({
  name: 'privacypolicy',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchPrivacyPolicy.pending]: (state) => {
      state.loading = true;
    },
    [fetchPrivacyPolicy.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.PrivacyPolicy = payload || {};
      state.loading = false;
    },
    [fetchPrivacyPolicy.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updatePrivacyPolicy.pending]: (state) => {
      state.loading = true;
    },
    [updatePrivacyPolicy.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.PrivacyPolicy = payload || {};
      state.loading = false;
    },
    [updatePrivacyPolicy.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default privacyPolicySlice.reducer;
