import { AddTwoTone } from '@mui/icons-material';
import { Typography,  Grid, Button } from '@mui/material';


import { useSelector } from 'react-redux';
import ServiceCreationModal from '../Services/ServiceCreationModal';
import { useState } from 'react';
import SubscriptionCreationModal from './SubscriptionCreateModal';



function PageHeaderPlan() {
  const authUser = useSelector((x) => x.user);
  const user = {
    name: (authUser?.profile?.firstname && authUser?.profile?.lastname) ?
      `${authUser.profile.firstname} ${authUser.profile.lastname}` :
      authUser?.profile?.email || '',
    avatar: '/static/images/avatars/1.jpg'
  };


  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
        Subscriptions
        </Typography>
        <Typography variant="subtitle2">
          {user.name}, these are  the current subscriptions
        </Typography>
      </Grid>

      <Grid item>
        <Button
          sx={{ mt: { xs: 2, md: 0 }, borderRadius: 30 }}
          variant="contained"
          startIcon={<AddTwoTone fontSize="small" />}
          onClick={handleOpenModal} // Open the modal on button click
        >
          Create subscription
        </Button>
        <SubscriptionCreationModal open={isModalOpen} onClose={handleCloseModal} />

      </Grid>
      
    </Grid>
  );
}

export default PageHeaderPlan;
