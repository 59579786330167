/* eslint-disable no-unused-vars */
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  MenuItem,
  TextField,
  Typography,
  Grid
} from '@mui/material';
import {addService} from '../../../redux/servicesSlice';
import {
  fetchbusinessAreas,
  fetchbusinessAreasActive
} from 'src/redux/businessAreaSlice';

const BusinessAreaOptions = [
  {label: 'Hotels', value: 'hotels'},
  {label: 'Restaurants', value: 'restaurants'},
  {label: 'Caterer', value: 'caterer'},
  {label: 'Bakery Pastry', value: 'bakery_pastry'},
  {label: 'Fine Grocery', value: 'fine_grocery'},
  {label: 'Coffee', value: 'coffee'},
  {label: 'Bar Brewery', value: 'bar_brewery'},
  {label: 'Beach Bar', value: 'beach_bar'}
];

const ServiceCreationModal = ({open, onClose}) => {
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState('');
  const [business_area, setBusiness_area] = useState('');
  const [active, setActive] = useState(true); // Initialize active as true
  const [errorMessage, setErrorMessage] = useState('');
  const data = useSelector((x) => x.businessAreas.businessAreas || []);

  const handleSubmit = () => {
    if (!serviceName || !business_area) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    }
    const serviceData = {
      serviceName,
      business_area,
      active
    };

    dispatch(addService(serviceData))
      .unwrap()
      .then(() => {
        setServiceName('');
        setBusiness_area('');
        setErrorMessage('');
        setActive(true);
        onClose();
      })
      .catch((error) => {
        setErrorMessage(error?.message);
      });
  };
  useEffect(() => {
    //dispatch(fetchbusinessAreas());
    console.log('/data' + JSON.stringify(data));
    dispatch(fetchbusinessAreasActive());
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Service</DialogTitle>
      <DialogContent>
        <Grid container>
        {errorMessage && (
          <Typography variant="body2" color="error" marginTop={1}>
            {errorMessage}
          </Typography>
        )}
        <TextField
          label="Service Name"
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Business Area</InputLabel>
          <Select
            label="Business Area"
            value={business_area}
            onChange={(e) => setBusiness_area(e.target.value)}
          >
            {data.map((option, index) => (
              <MenuItem key={index} value={option.business_name}>
                {option.business_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          }
          label="Status (Active/Inactive)"
        />
        </Grid>
      </DialogContent>
      <DialogActions style={{marginBottom:5, display:'flex', justifyContent:'center',alignItems:"center"}}>
        <Button
          sx={{borderRadius: '30px', border: '1px solid white', color: 'white'}}
          onClick={onClose}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          sx={{borderRadius: '30px'}}
          onClick={handleSubmit}
        >
          Yes, Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceCreationModal;
