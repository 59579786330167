import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Subscriptions from './content/applications/subscriptions/Subscriptions';
import SubscriptionTable from './content/applications/subscriptions/SubscriptionTable';
import SubscriptionIndex from './content/applications/subscriptions';
import Performance from './content/applications/payment/IndexPayment';
import Index from './content/applications/payment/IndexPayment';
import Annuaire from './content/applications/tax/Annuaire';
import IndexAnnuaire from './content/applications/tax/IndexAnnuaire';
import IndexPayment from './content/applications/payment/IndexPayment';
import IndexCustomers from './content/applications/Customers/IndexCustomers';
import IndexSuggestionsNotTreated from './content/applications/Suggestions/SuggestionsNotTreated/IndexSuggestionsNotTreated';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Services = Loader(
  lazy(() => import('src/content/applications/Services'))
);
const PrivacyPolicy = Loader(
  lazy(() => import('src/content/applications/PrivacyPolicy'))
);
const UsefulLinks = Loader(
  lazy(() => import('src/content/applications/UsefulLinks'))
);
const AppServices = Loader(
  lazy(() => import('src/content/applications/AppServices'))
);
const TermOfUse = Loader(
  lazy(() => import('src/content/applications/TermOfUse'))
);
const Footer = Loader(lazy(() => import('src/content/applications/Footer')));
const Map = Loader(lazy(() => import('src/content/applications/Map')));
const AboutUs = Loader(lazy(() => import('src/content/applications/Aboutus')));
const SocialLink = Loader(
  lazy(() => import('src/content/applications/SocialLinks'))
);
const ContactUs = Loader(
  lazy(() => import('src/content/applications/Contactus'))
);
const FrequentQuestions = Loader(
  lazy(() => import('src/content/applications/frequentQuestion'))
);
const BusinessArea = Loader(
  lazy(() => import('src/content/applications/BusinessArea'))
);
const Companies = Loader(
  lazy(() => import('src/content/applications/Companies'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);

const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const CompaniesTable = Loader(
  lazy(() => import('src/content/applications/Companies/CompaniesTable'))
);

const CompanyDetails = Loader(
  lazy(() => import('src/content/applications/Companies/details'))
);
const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Suggestions = Loader(
  lazy(() => import('src/content/applications/Suggestions'))
);
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));
const ForgetPassword = Loader(
  lazy(() => import('src/content/ForgetPassword/ForgotPassword'))
);
const ResetPassword = Loader(
  lazy(() => import('src/content/ForgetPassword/ResetPassword'))
);
// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const IndexTax = Loader(
  lazy(() => import('src/content/applications/tax/Index'))
);

const routes = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'forgetPassword',
        element: <ForgetPassword />
      },
      {
        path: 'resetpassword',
        element: <ResetPassword />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'tasks',
        element: <Tasks />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'CRM',
    element: <SidebarLayout />,
    children: [
      { path: 'PrivacyPolicy', element: <PrivacyPolicy /> },
      { path: 'SocialLink', element: <SocialLink /> },
      { path: 'TermOfUse', element: <TermOfUse /> },
      { path: 'AboutUs', element: <AboutUs /> },
      { path: 'ContactUs', element: <ContactUs /> },
      { path: 'Footer', element: <Footer /> },
      { path: 'Map', element: <Map /> },
      { path: 'FrequentQuestions', element: <FrequentQuestions /> },
      { path: 'UsefulLinks', element: <UsefulLinks /> },
      { path: 'AppServices', element: <AppServices /> }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile" replace />
      },
      {
        path: 'services',
        element: <Services />
      },
      {
        path: 'suggestions',
        element: <Suggestions />
      },
      {
        path: 'suggestions',
        children : [
          {
            path : 'not_treated',
            element: <IndexSuggestionsNotTreated/>,
          }
        ]
      },
      {
        path: 'BusinessArea',
        element: <BusinessArea />
      },
      {
        path: 'tax',
        element: <IndexTax />
      },
      {
        path: 'directory',
        element: <IndexAnnuaire />
      },
      {
        path: 'companies',
        element: <Companies />,
        children: [
          {
            path: '',
            element: <CompaniesTable />
          },

          {
            path: 'details/:id',
            element: <CompanyDetails />
          }
        ]
      },
      {
        path: 'subscriptions',
        element: <SubscriptionIndex />,
        children: [
          {
            path: '',
            element: <SubscriptionTable />
          }
        ]
      },
      {
        path: 'payments',
        element: <IndexPayment />,
        children: [
          {
            path: '',
            element: <SubscriptionTable />
          }
        ]
      },

      {
        path: 'customers',
        element: <IndexCustomers />
      },

      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },

  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
