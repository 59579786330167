import React from 'react'
import { Card } from '@mui/material';
import CustomersTable from './CustomersTable';


function Customers() {
  return (
    <Card>
       <CustomersTable />
    </Card>
  )
}

export default Customers;