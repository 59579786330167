import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {logout} from './authSlice';
import api from './config'; // Import the configured Axios instance
const initialState = {
  SocialLinks: [],
  recordsTotal: 0,

  loading: false,
  isSuccess: false,
  errors: []
};
export const fetchSocialLinks = createAsyncThunk(
  'sociallinks/fetchSocialLinks',
  async (params, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/crm-module/SocialLink', {params});
      return response?.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const addSocialLink = createAsyncThunk(
  'sociallinks/addSocialLink',
  async (sociallinkdata, {rejectWithValue, dispatch}) => {
    try {
      const bodyData = {
        platform: sociallinkdata.platform,
        link: sociallinkdata.link
      };
      const response = await api.post('/crm-module/SocialLink/add', bodyData);

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateSocialLink = createAsyncThunk(
  'sociallinks/updateSocialLink',
  async (updatedsociallink, {rejectWithValue, dispatch}) => {
    try {
      const {id, platform, link} = updatedsociallink;
      console.log(id);
      let params = {platform, link};
      const response = await api.put(`/crm-module/SocialLink/${id}`, params);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const archiveSocialLink = createAsyncThunk(
  'sociallinks/archiveSocialLink',
  async (sociallink, {rejectWithValue, dispatch}) => {
    try {
      const id = sociallink._id;
      const data = {active: !sociallink.active};
      const response = await api.put(
        `/crm-module/SocialLink/archive/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const SocialLinksSlice = createSlice({
  name: 'sociallinks',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchSocialLinks.pending]: (state) => {
      state.loading = true;
    },
    [fetchSocialLinks.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.SocialLinks = payload;
      state.recordsTotal = payload?.recordsTotal || 0;

      state.loading = false;
    },
    [fetchSocialLinks.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addSocialLink.pending]: (state) => {
      state.loading = true;
    },
    [addSocialLink.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      const newSocialLink = {...payload};
      state.SocialLinks.push(newSocialLink);
      state.loading = false;
    },
    [addSocialLink.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateSocialLink.pending]: (state) => {
      state.loading = true;
    },
    [updateSocialLink.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.SocialLinks = state.SocialLinks.map((SocialLink) =>
        SocialLink._id === payload._id
          ? {...payload, _id: payload._id}
          : SocialLink
      );
      state.loading = false;
    },
    [updateSocialLink.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [archiveSocialLink.pending]: (state) => {
      state.loading = true;
    },
    [archiveSocialLink.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.SocialLinks = state.SocialLinks.map((SocialLink) =>
        SocialLink._id === payload._id
          ? {...payload, _id: payload._id}
          : SocialLink
      );
      state.loading = false;
    },
    [archiveSocialLink.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});
export default SocialLinksSlice.reducer;
