/* eslint-disable no-unused-vars */
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider
} from '@mui/material';
import {loadStripe} from '@stripe/stripe-js';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {LoadingButton} from '@mui/lab';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';

import {fetchById, updatePayment} from 'src/redux/PaymentSlice';
import Label from 'src/components/Label';
import {Circle, CircleOutlined, HomeMini} from '@mui/icons-material';
import {
  CardElement,
  useStripe,
  useElements,
  Elements
} from '@stripe/react-stripe-js';
const stripePromise = loadStripe(
  'pk_test_51NFfUfAH3eU6rKRWTJ5HdRRrtkcaXlfDPbjxDFd6oXFafbzVanH4ffh2TYUYAABBwSpmYQcC8YcCm4k4s3CL5dCT00c1pkv4pK'
);

function EditPayment ({open, onClose, selected_payment}) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const paySelected = useSelector((x) => x.payment.selected_payment);
  const [status, setStatus] = useState(paySelected?.status || '');
  const [brand, setBrand] = useState(paySelected?.brand || '');
  const [last4, setLast4] = useState(paySelected?.last4 || '');
  const [exp_month, setExp_month] = useState(paySelected?.exp_month || '');
  const [exp_year, setExp_year] = useState(paySelected?.exp_year || '');
  const [postal_code, setPostal_code] = useState(
    paySelected?.postal_code || ''
  );
  const [price_ht, setPrice_ht] = useState(paySelected?.price_ht || '');
  const [tva, setTva] = useState(paySelected?.tva || '');
  const [price_net, setPrice_net] = useState(paySelected?.price_net || '');
  const [customer, setCustomer] = useState(paySelected?.customer || null);
  const [abonnement, setAbonnement] = useState(paySelected?.abonnement || null);
  const [createdAt, setCreatedAt] = useState(paySelected?.createdAt || '');
  const [updatedAt, setUpdatedAt] = useState(paySelected?.updatedAt || '');

  const [refund_message, setRefund_message] = useState(
    paySelected?.refund_message || ''
  );
  const [refund_status, setRefund_status] = useState(
    paySelected?.refund_status || ''
  );

  const loading = useSelector((x) => x.payment.loading);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dispatch(fetchById(selected_payment));
  }, [selected_payment]);
  useEffect(() => {
    if (paySelected) {
      setStatus(paySelected.status);
      setBrand(paySelected?.brand);
      setLast4(paySelected?.last4);
      setExp_month(paySelected?.exp_month);
      setExp_year(paySelected?.exp_year);
      setStatus(paySelected?.status);
      setPostal_code(paySelected?.postal_code);
      setPrice_ht(paySelected?.price_ht);
      setTva(paySelected?.tva);
      setPrice_net(paySelected?.price_net);
      setCustomer(paySelected?.customer);
      setAbonnement(paySelected?.abonnement);
      setCreatedAt(paySelected?.createdAt);
      setUpdatedAt(paySelected?.updatedAt);
      setRefund_message(paySelected?.refund_message);
      setRefund_status(paySelected?.refund_status);
    }
  }, [paySelected]);

  const handleSubmit = () => {
    if (!status) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    }
    const payData = {
      id: paySelected._id,
      brand: paySelected.brand,
      last4: paySelected.last4,
      exp_month: paySelected.exp_month,
      exp_year: paySelected.exp_year,
      cvc: paySelected.cvc,
      status: status,
      active: paySelected.active,
      postal_code: paySelected.postal_code,
      cvc_check: paySelected.cvc_check,
      price_ht: paySelected.price_ht,
      tva: paySelected.tva,
      price_net: paySelected.price_net,
      abonnement: paySelected?.abonnement
    };
    dispatch(updatePayment(payData))
      .unwrap()
      .then(() => {
        setStatus('');
        onClose();
      })
      .catch((error) => {
        setErrorMessage(error?.message);
      });
  };

  const handleRefund = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      // Handle the case where CardElement is not available
      console.error('CardElement is not available');
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Handle successful payment
      const card = paymentMethod?.card;
      const billingCard = paymentMethod?.billing_details;

      setCardData({
        number: {last4 : card?.last4},
        expiry: `${exp_month}/${exp_year}`,
        cvc: "123",
        name: `${customer?.firstname
          ?.charAt(0)
          .toUpperCase()}${customer?.firstname?.slice(
          1
        )} ${customer?.lastname?.toUpperCase()}`
      });

      // Calculate the refund amount (70% of total)
      const refundAmount = price_net * 0.7;

      // Make the refund with Stripe API
      const refund = await stripe.refunds.create({
        payment_intent: paymentMethod.id,
        amount: Math.round(refundAmount * 100) // Convert to cents
      });

      console.log("refund"+refund);

      // Handle the refund response
      if (refund.status === 'succeeded') {
        // Refund successful, continue with your logic
        const paymentData = {
          refund_status: 'refunded',
          price_net: refund.amount / 100 // Convert back to dollars
        };

        // Dispatch payment data
        dispatch(updatePayment(paymentData));
      } else {
        // Refund failed, handle accordingly
        setError('Error while refund processing ');
      }
    }
  };


  const [cardData, setCardData] = useState({
    number: last4 ,
    expiry: exp_month+"/"+exp_year ,
    cvc: "123" ,
    name: customer?.firstname+" "+customer?.lastname,
    acceptedCards: ["mastercard","visa"],
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedDateTime = `${formattedDate} - ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }

  const handleInputChange = (event) => {
    // Vérifiez si le champ de la carte est complet et valide
    if (event.complete && !event.error) {
      // Accédez aux informations de la carte depuis event.paymentMethod.card
      const cardInfo = event.paymentMethod.card;

      // Mettez à jour l'état avec les informations de la carte
      setCardData({
        number: cardData.number,
        expiry: `${cardData.exp_month}/${cardData.exp_year}`,
        cvc: '123',
        name: cardData?.name// Vous pouvez remplacer par le nom du titulaire de la carte
      });
    } 
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Payment Sheet</DialogTitle>
      {loading ? (
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          startIcon={<RefreshTwoToneIcon />}
        />
      ) : (
        <DialogContent style={{width: 400}}>
          {errorMessage && (
            <Typography variant="body2" color="error" marginTop={1}>
              {errorMessage}
            </Typography>
          )}
           
          <Grid container>
            <Typography variant="h6" component="h6" gutterBottom>
              Customer Details
              <Divider style={{border: '1px solid white', width: 70}} />
            </Typography>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>Name</span>{' '}
              &nbsp; &nbsp;{' '}
              {customer?.firstname?.charAt(0).toUpperCase() +
                customer?.firstname?.slice(1)}{' '}
              {customer?.lastname?.toUpperCase()}
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>Email</span>{' '}
              &nbsp; &nbsp; {customer?.email}
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>Phone</span>{' '}
              &nbsp; &nbsp; {customer?.phone}
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>
                Postal Code
              </span>{' '}
              &nbsp; &nbsp; {postal_code}
            </p>
          </Grid>
          <Grid container mt={1}>
            <Typography variant="h6" component="h6" gutterBottom>
              Subscription Details
              <Divider style={{border: '1px solid white', width: 70}} />
            </Typography>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>Title</span>{' '}
              &nbsp; &nbsp; {abonnement?.title}
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>Price</span>{' '}
              &nbsp; &nbsp; {abonnement?.price} {' $ '}
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>
                Details
              </span>{' '}
            </p>
          </Grid>
          <Grid container>
            {abonnement?.details?.map((item) => (
              <>
                <Grid container>
                  <CircleOutlined style={{marginTop: 5, fontSize: 10}} /> &nbsp;{' '}
                  {item}
                </Grid>
              </>
            ))}
          </Grid>
          <Grid container mt={1}>
            <Typography variant="h6" component="h6" gutterBottom>
              Payment Information
              <Divider style={{border: '1px solid white', width: 70}} />
            </Typography>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>Brand</span>{' '}
              &nbsp; &nbsp; {brand}
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>
                Last 4 Card Number
              </span>{' '}
              &nbsp; &nbsp; **** **** **** {last4}
            </p>
          </Grid>

          <Grid container>
            <Grid container>
              <p>
                <span style={{color: '#13B871', fontWeight: 'bold'}}>
                  HT Price
                </span>{' '}
                &nbsp; &nbsp; {price_ht} {' $ '}
              </p>
            </Grid>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>TVA</span>{' '}
              &nbsp; &nbsp; {tva} %
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>
                Net Price
              </span>{' '}
              &nbsp; &nbsp;{' '}
              <Label color="light">
                {isNaN(parseFloat(price_net))
                  ? 'Invalid Price'
                  : parseFloat(price_net).toFixed(2)}{' '}
                {' $ '}
              </Label>
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>Expire</span>{' '}
              &nbsp; &nbsp; {exp_month}
              {'/'}
              {exp_year}
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>
                Created At
              </span>{' '}
              &nbsp; &nbsp; <Label color="light">{formatDate(createdAt)}</Label>
            </p>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>
                Updated At
              </span>{' '}
              &nbsp; &nbsp; <Label color="light">{formatDate(updatedAt)}</Label>
            </p>
          </Grid>

          <Grid container mt={1}>
            <Typography variant="h6" component="h6" gutterBottom>
              Edit Payment Status
              <Divider style={{border: '1px solid white', width: 160}} />
            </Typography>
          </Grid>

          <FormControl fullWidth margin="normal">
            <InputLabel>Payment Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="rejected">Reject</MenuItem>
              <MenuItem value="canceled">Cancel</MenuItem>
            </Select>
          </FormControl>

          <Grid container mt={1}>
            <Typography variant="h6" component="h6" gutterBottom>
              Refund Information
              <Divider style={{border: '1px solid white', width: 70}} />
            </Typography>
            <Label color="light">
              {' '}
              <small>&nbsp; (70% of payment will be refunded)</small>
            </Label>
          </Grid>
          <Grid container>
            <p>
              <span style={{color: '#13B871', fontWeight: 'bold'}}>
                Request
              </span>{' '}
              &nbsp; &nbsp; <Label color="light">{refund_message}</Label>
            </p>
          </Grid>
          <FormControl fullWidth margin="normal">
            <InputLabel>Refund Status</InputLabel>
            <Select
              label="Refund Status"
              value={refund_status}
              onChange={(e) => setRefund_status(e.target.value)}
            >
              <MenuItem value="refunded">Refund</MenuItem>
              <MenuItem value="not refunded">Not Refund</MenuItem>
            </Select>
          </FormControl>
          <Grid container justifyContent={"end"} alignItems={"end"}>
          <Button
            variant="contained"
            disabled={refund_status === "not refunded" || refund_status === "pending"}
            sx={{borderRadius: '30px'}}
            onClick={handleRefund}
          >
            Yes, refund
          </Button>
          <CardElement
              
            
              onChange={handleInputChange}
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: `${"#fff"}`,
                    "::placeholder": {
                      color: `${"#aab7c4"}`,
                    },
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    padding: "10px",
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </Grid>
        </DialogContent>
      )}

      <DialogActions
        style={{
          marginBottom: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          sx={{borderRadius: '30px', border: '1px solid white', color: 'white'}}
          onClick={onClose}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          sx={{borderRadius: '30px'}}
          onClick={handleSubmit}
          disabled={loading}
        >
          Yes, update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StripePayment = ({open, onClose, selected_payment}) => {
  return (
    <Elements stripe={stripePromise}>
      <EditPayment open={open} onClose={onClose} selected_payment={selected_payment} />
    </Elements>
  );
};

export default StripePayment;

