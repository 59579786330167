import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {logout} from './authSlice';
import api from './config'; // Import the configured Axios instance

const initialState = {
  payments: [],
  selected_payment: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: []
};

export const fetchPayments = createAsyncThunk(
  'payment/fetchPayments',
  async (params, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/payment', {params});
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerPayments = createAsyncThunk(
  'payment/fetchCustomerPayments',
  async (params, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/payment/by-customer');
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchById = createAsyncThunk(
  'payment/fetchById',

  async (paymentId, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get(`/payment/${paymentId}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        // Gérer d'autres codes d'erreur de réponse ici
        if (error.response.status === 401) {
          dispatch(logout());
        }
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

export const addPayment = createAsyncThunk(
  'payment/addPayment',
  async (paymentData, {rejectWithValue, dispatch}) => {
    try {
      const params = {
        brand: paymentData.brand,
        last4: paymentData.last4,
        exp_month: paymentData.exp_month,
        exp_year: paymentData.exp_year,
        cvc: paymentData.cvc,
        status: 'active',
        active: paymentData.active,
        postal_code: paymentData.postal_code,
        cvc_check: paymentData.cvc_check,
        price_ht: paymentData.price_ht,
        tva: paymentData.tva,
        price_net: paymentData.price_net,
        abonnement: paymentData?.abonnement
      };
      const response = await api.post('/payment/add', params);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePayment = createAsyncThunk(
  'services/updatePayment',
  async (updatePayment, {rejectWithValue, dispatch}) => {
    try {
      // eslint-disable-next-line camelcase
      const {
        id,
        brand,
        last4,
        exp_month,
        exp_year,
        cvc,
        active,
        postal_code,
        cvc_check,
        price_ht,
        tva,
        price_net,
        abonnement,
        status
      } = updatePayment;

      // eslint-disable-next-line camelcase
      const params = {
        status,
        brand,
        last4,
        exp_month,
        exp_year,
        cvc,
        active,
        postal_code,
        cvc_check,
        price_ht,
        tva,
        price_net,
        abonnement
      };
      const response = await api.put(`/payment/${id}`, params);

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const PaymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: {
    [addPayment.pending]: (state) => {
      state.loading = true;
    },
    [addPayment.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      const newData = {...payload, _DT_RowId: payload._id}; // Create a new service object with _DT_RowId attribute
      state.payments.push(newData); // Add the new service to the services array
      state.loading = false;
    },
    [addPayment.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [fetchCustomerPayments.pending]: (state) => {
      state.loading = true;
    },
    [fetchCustomerPayments.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.payments = payload;
      state.loading = false;
    },
    [fetchCustomerPayments.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [fetchPayments.pending]: (state) => {
      state.loading = true;
    },
    [fetchPayments.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.payments = payload.data || [];
      state.recordsTotal = payload.recordsTotal || 0;
      state.loading = false;
    },
    [fetchPayments.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [fetchById.pending]: (state) => {
      state.loading = true;
    },
    [fetchById.fulfilled]: (state, {payload}) => {
      state.isSuccess = true;
      state.selected_payment = payload;
      state.loading = false;
      console.log(
        'selected state payment' + JSON.stringify(state.selected_payment)
      );
      console.log('payload ' + JSON.stringify(payload));
    },
    [fetchById.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updatePayment.pending]: (state) => {
      state.loading = true;
    },
    [updatePayment.fulfilled]: (state, {payload}) => {
      // Update the selected service with the updated data
      state.payments = state.payments.map((payment) =>
        payment.DT_RowId === payload._id
          ? {...payload, DT_RowId: payload._id}
          : payment
      );

      state.loading = false;
    },
    [updatePayment.rejected]: (state, {payload}) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    }
  }
});

export default PaymentSlice.reducer;
