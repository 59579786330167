import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
    customers: [],
    selected_customer: null,
    loading: false,
    isSuccess: false,
    errors: [],
    recordsTotal: 0
};


export const fetchCustomers = createAsyncThunk(
    'customers/fetchCustomers',
    async (params, { rejectWithValue, dispatch }) => {
      try {
        const response = await api.get('/user/customers/all', { params });
        return response.data;
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {},
    extraReducers: {
      [fetchCustomers.pending]: (state) => {
        state.loading = true;
      },
      [fetchCustomers.fulfilled]: (state, { payload }) => {
        state.isSuccess = true;
        state.customers = payload.data || [];
        state.recordsTotal = payload.recordsTotal || 0;
        state.loading = false;
      },
      [fetchCustomers.rejected]: (state, { payload }) => {
        if (payload.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      },
    
    }
  });
  
  export default customerSlice.reducer;